import React, { useCallback, useMemo } from 'react';
import { useTable, useExpanded } from 'react-table';

type TableProps = {
    columns: any;
    data: any;
    renderRowSubComponent?: any;
    searchCriteria: string;
    className?: string;
}

const CustomTable = (props: TableProps) => {
    const { columns, data, renderRowSubComponent, searchCriteria, className } = props;

    // Memoize table instance
    const tableInstance = useTable({ columns, data }, useExpanded);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
    } = tableInstance;

    // Memoize search filter function
    const searchFilter = useCallback((entity: any) => {
        const s = searchCriteria?.toLowerCase();
        return (
            searchCriteria === "" ||
            entity.branch?.users?.some(
                (el: any) =>
                    (el.firstname.toLowerCase() + " " + el.lastname.toLowerCase()).includes(s)
            ) ||
            entity.branch?.name.toLowerCase().includes(s) ||
            (entity.customer.firstName.toLowerCase() +
                " " +
                entity.customer.lastName?.toLowerCase()).includes(s)
        );
    }, [searchCriteria]);

    // Memoize filtered rows to prevent unnecessary recalculations
    const filteredRows = useMemo(() => rows.filter((row) => searchFilter(row.original)), [
        rows,
        searchFilter,
    ]);

    return (
        <table {...getTableProps()} className={`table-auto w-full ${className}`}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className="px-4 py-2 text-sm font-medium tracking-wider text-gray-700 uppercase border-b bg-gray-50"
                                key={column.id}
                            >
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {filteredRows.map((row) => {
                    prepareRow(row);
                    return (
                        <React.Fragment key={row.getRowProps().key}>
                            <tr {...row.getRowProps()} className="border-b hover:bg-gray-100">
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps()}
                                        className="px-4 py-2 text-sm font-normal text-gray-700"
                                        key={cell.getCellProps().key}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                            {row.isExpanded ? (
                                <tr key={`${row.id}-expanded`}>
                                    <td colSpan={visibleColumns.length}>
                                        {renderRowSubComponent({ row })}
                                    </td>
                                </tr>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    );
};
export default CustomTable;
