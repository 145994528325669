import { float, int } from "./primitives";
import { IProductImage } from "./product-request";
import { IS3UploadRequestParams } from "./quote-request";
export enum WeightUnit {
    POUND = 'lb',
    OUNCE = 'oz',
    KILOGRAM = 'kg',
    GRAM = 'g'
}

export enum DimensionalUnit {
    INCH = 'in',
    CENTIMETER = 'cm'
}

export type VariantAttribute = {
    name: string;
    value: string;
}

export interface ICreateProductCategoryParams {
    name: string;
}

export interface IProductCategory {
    id: int;
    name: string;
    products?: IProduct[];
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICreateShippingGroupParams {
    name: string;
    baseCost: float;
    maxQuantity: int | null;
}

export interface IShippingGroup {
    id: int;
    name: string;
    products?: IProduct[];
    baseCost: float;
    maxQuantity: int | null;
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICreateProductVariantParams {
    attributes: VariantAttribute[];
    description?: string;
    weightUnit?: WeightUnit;
    dimUnit?: DimensionalUnit;
    weight?: float;
    height?: float;
    width?: float;
    length?: float;
    retailPrice?: float;
    declaredValue?: float;
    listed: boolean;
}

export interface IProductVariant {
    id: int;
    product?: IProduct;
    attributes: VariantAttribute[];
    description: string | null;
    weightUnit: WeightUnit | null;
    dimUnit: DimensionalUnit | null;
    weight: float | null;
    height: float | null;
    width: float | null;
    length: float | null;
    retailPrice: float | null;
    declaredValue: float | null;
    listed: boolean;
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICreateProductParams {
    title: string;
    description: string;
    weightUnit: WeightUnit;
    dimUnit: DimensionalUnit;
    weight: float;
    height: float;
    width: float;
    length: float;
    retailPrice: float;
    declaredValue: float;
    moqValue: float;
    listed: boolean;
    permit24V: boolean;
    permitPurple: boolean;
    guid: string;
}

export interface IProduct {
    id: int;
    title: string;
    description: string;
    weightUnit: WeightUnit;
    dimUnit: DimensionalUnit;
    weight: float;
    height: float;
    width: float;
    length: float;
    retailPrice: float;
    declaredValue: float;
    variants?: IProductVariant[];
    categories?: IProductCategory[];
    shippingGroup?: IShippingGroup;
    moqValue: float;
    listed: boolean;
    permit24V: boolean;
    permitPurple: boolean;
    image: IProductImage | null,
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    guid: string;
    minPurchaseQuantity: int;
}


export interface ICreateProductImageParams extends IS3UploadRequestParams { }
