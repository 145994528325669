import { ChannelType, float, IBookableUser, IInstall, int, TypeOfLights } from '@common.abstractions'
import { RetrieveBookableUser, RetrieveBookableUsers } from '@frontend/services/bookable-users';
import { sendNewSalesNotifcation, UpdateInstall, UpdateInstallBookableUsers } from '@frontend/services/installs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Row } from 'react-table'
import { toast } from 'react-toastify';
import AuthContext from "@frontend/contexts/AuthContext";
import { RetrieveProductVariants } from '@frontend/services/products';
import { Equals } from '@common.api';
import { formatPhoneNumber } from '@common.tools';

export default function InstallSubRow({ row }: { row: Row<IInstall> }) {
    const [selectedUserIds, setSelectedUserIds] = useState<int[]>([]);
    const [additionalSelects, setAdditionalSelects] = useState<number>(0);
    const [scheduledUsers, setScheduledUsers] = useState<IBookableUser[]>([]);
    const [bookableUsers, setBookableUsers] = useState<IBookableUser[]>([]);

    const authContext = useContext(AuthContext);

    const channelColors = useRef<string[]>([]);
    const [channel, setChannel] = useState<{ ft: int | null; type: string | null; color: string | null }[]>([]);

    // Fetch all bookable users when the component mounts
    useEffect(() => {
        const fetchBookableUsers = async () => {
            const response = await RetrieveBookableUsers({
                relations: ['user'],
            });
            if (!response) {
                toast.error('Failed to retrieve bookable users.');
                return;
            }

            // Store the full list of bookable users
            setBookableUsers(response);
        };

        fetchBookableUsers();
        getChannelColors();
    }, []);

    // Initialize with the row's bookable users and set a broader list of users
    // Filter bookable users based on the row's branchId and initialize selected users and dropdowns
    useEffect(() => {
        if (row.original && bookableUsers.length > 0) {
            // Filter users with scheduledLocations that match the current row's branch id
            const filteredScheduledUsers = bookableUsers.filter((user) =>
                user.scheduledLocations.some((location) => location === row.original.branch.id)
            );
            setScheduledUsers(filteredScheduledUsers);

            // Initialize the selected user IDs from the row's existing bookableUsers
            const initialUserIds = row.original.bookableUsers?.map((user) => user.id) || [];
            setSelectedUserIds(initialUserIds);

            // Set the number of additional selects based on the number of assigned users
            setAdditionalSelects(Math.max(0, initialUserIds.length - 1));
        }
    }, [row.original, bookableUsers]);

    useEffect(() => {
        if (row.original.channel) {
            setChannel([...row.original.channel]);
        }
    }, [row.original.channel]);


    const handleBookableUserChange = async (index: number, e: any) => {
        const userId = int.parse(e.target.value);
        const updatedUserIds = [...selectedUserIds];
        updatedUserIds[index] = userId;

        setSelectedUserIds(updatedUserIds);

        // Update backend with new bookable user IDs
        const response = await UpdateInstallBookableUsers(row.original.id, updatedUserIds);
        if (!response) {
            toast.error('Failed to update bookable users.');
            return;
        }

        // Update row.original.bookableUsers
        const updatedBookableUsers = scheduledUsers.filter(user => updatedUserIds.includes(user.id));
        row.original.bookableUsers = updatedBookableUsers;

        toast.success('Bookable users updated successfully.');
    };

    const handleAddDropdown = () => {
        setAdditionalSelects((prev) => prev + 1);
    };

    const handleRemoveDropdown = async (index: number) => {
        const updatedUserIds = [...selectedUserIds];
        updatedUserIds.splice(index, 1);
        setSelectedUserIds(updatedUserIds);

        // Update backend with new bookable user IDs after removal
        const response = await UpdateInstallBookableUsers(row.original.id, updatedUserIds);
        if (!response) {
            toast.error('Failed to update bookable users.');
            return;
        }

        // Update row.original.bookableUsers
        const updatedBookableUsers = scheduledUsers.filter(user => updatedUserIds.includes(user.id));
        row.original.bookableUsers = updatedBookableUsers;

        setAdditionalSelects((prev) => Math.max(prev - 1, 0));
        toast.success('Bookable user removed successfully.');
    };

    const handleHouseLevelBlur = async (e: React.FocusEvent<HTMLInputElement>, row: Row<IInstall>) => {
        const newHouseLevel = float.parse(e.target.value);
        const updatedInstall = await UpdateInstall(row.original.id, { houseLevel: newHouseLevel, auditUserId: authContext!.user!.accountId });
        if (!updatedInstall) {
            toast.error('Failed to update house level');
            return;
        }
        row.original.houseLevel = newHouseLevel;
    };

    const handleNotesChange = async (e: React.FocusEvent<HTMLTextAreaElement>, row: Row<IInstall>) => {
        const newNotes = e.target.value.trim();

        if (newNotes === 'RESEND-DILLY') {
            await sendNewSalesNotifcation(row.original.id, true);
        }
        const updatedInstall = await UpdateInstall(row.original.id, { notes: newNotes, auditUserId: authContext!.user!.accountId })

        if (!updatedInstall) {
            toast.error('Failed to update notes');
            return;
        }
        row.original.notes = newNotes;
    };

    const handleTypeOfLightsChange = async (e: React.ChangeEvent<HTMLSelectElement>, row: Row<IInstall>) => {
        const newTypeOfLights = e.target.value === '' ? null : e.target.value as TypeOfLights;
        const updatedInstall = await UpdateInstall(row.original.id, { typeOfLights: newTypeOfLights, auditUserId: authContext!.user!.accountId });
        if (!updatedInstall) {
            toast.error('Failed to update type of lights');
            return;
        }
        row.original.typeOfLights = newTypeOfLights;
    }


    const getChannelColors = async () => {
        const productId = int(40);
        const values: any = []

        const variants = await RetrieveProductVariants({
            relations: ['product'],
            filters: {
                product: {
                    id: Equals(productId)
                }
            }
        })

        variants?.forEach((variant) => {
            values.push(variant.attributes[0].value);
        });

        channelColors.current = values;
    }

    // Helper functions for rendering subcomponents data
    const getFtSold = (pricingTableItems: any[], sku: string): number => {
        const matchingItems = pricingTableItems.filter(i => i.sku === sku)
        if (matchingItems.length === 0) return 0;

        const selectedItems = [];

        for (const item of matchingItems) {
            if (!item.options.optional || item.options.optional_selected) {
                selectedItems.push(item);
            }
        }

        const ftSold = selectedItems.reduce((acc, item) => acc + int.parse(item.qty), 0);
        return ftSold;
    }

    const getFtSoldForItem = (items: any, sku: string) => {
        return getFtSold(items, sku);
    };

    const handleChannelChange = async (index: number, field: 'ft' | 'type' | 'color', value: any) => {
        const updatedChannel = [...channel]; // Copy the current channel array
        updatedChannel[index] = {
            ...updatedChannel[index], // Copy the current object at the given index
            [field]: value, // Update the specific field (ft, type, or color)
        };

        setChannel(updatedChannel); // Update the local state immediately for UI

        try {
            // Send the updated channel array to the backend
            const response = await UpdateInstall(row.original.id, { channel: updatedChannel, auditUserId: authContext!.user!.accountId });

            if (response) {
                // If the backend update is successful, update row.original.channel as well
                row.original.channel = updatedChannel; // Directly update the row's channel data
                toast.success(`${field === 'ft' ? 'Footage' : field} updated successfully.`);
            }
        } catch (error) {
            toast.error(`Failed to update ${field}.`);
        }
    };
    const gutterGuardFt = row.original.rawDocument && getFtSoldForItem(row.original.rawDocument.pricing.tables[0].items, "173659843534");


    return (
        <div className="w-full lg:w-1/2 mx-auto h-full p-6 bg-white rounded-lg shadow-md border border-gray-200 overflow-auto">
            {/* Type of Lights and Sales Rep Section */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                <div className="flex flex-col">
                    <label className="text-xs font-semibold text-gray-700 mb-1">Type of Lights</label>
                    <select
                        className="w-full max-w-md px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 text-sm"
                        defaultValue={row.original.typeOfLights ?? ''}
                        onChange={(e) => handleTypeOfLightsChange(e, row)}
                    >
                        <option value="">Select...</option>
                        {Array.from(Object.values(TypeOfLights)).map((type, index) => (
                            <option key={index} value={type}>{type}</option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col">
                    <label className="text-xs font-semibold text-gray-700 mb-1">Sales Rep</label>
                    <div className="text-gray-900 text-sm">{row.original.salesRep?.firstname} {row.original.salesRep?.lastname}</div>
                </div>
            </div>

            {/* House Level and Phone Number */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                <div className="flex flex-col">
                    <label className="text-xs font-semibold text-gray-700 mb-1">House Level</label>
                    <input
                        type="number"
                        step="0.5"
                        max="5"
                        min="0"
                        defaultValue={row.original.houseLevel ?? ''}
                        onBlur={(e) => handleHouseLevelBlur(e, row)}
                        className="w-full max-w-md px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 text-sm"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="text-xs font-semibold text-gray-700 mb-1">Phone Number</label>
                    <div className="text-gray-900 text-sm">{formatPhoneNumber(row.original.customer.phone)}</div>
                </div>
            </div>

            {/* Installers Dropdowns */}
            <div className="mb-6">
                <label className="text-xs font-semibold text-gray-700 mb-2 block">Installers</label>

                {/* Primary Installer */}
                <select
                    className="w-full max-w-md px-2 py-1 mb-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 text-sm"
                    onChange={(e) => handleBookableUserChange(0, e)}
                    value={selectedUserIds[0] ?? ''}
                >
                    <option value="">Select User...</option>
                    {scheduledUsers?.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.user?.firstname} {user.user?.lastname}
                        </option>
                    ))}
                </select>

                {/* Additional Installers */}
                {Array.from({ length: additionalSelects }).map((_, index) => (
                    <div key={index + 1} className="flex items-center mb-2">
                        <select
                            className="w-full max-w-md px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 text-sm"
                            onChange={(e) => handleBookableUserChange(index + 1, e)}
                            value={selectedUserIds[index + 1] ?? ''}
                        >
                            <option value="">Select User...</option>
                            {scheduledUsers?.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.user?.firstname} {user.user?.lastname}
                                </option>
                            ))}
                        </select>
                        <button
                            className="ml-2 px-4 py-1 bg-red-500 text-white rounded-md shadow-sm hover:bg-red-600 text-sm"
                            onClick={() => handleRemoveDropdown(index + 1)}
                        >
                            Remove
                        </button>
                    </div>
                ))}

                <button
                    className="mt-4 ml-2 px-4 py-1 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 text-sm"
                    onClick={handleAddDropdown}
                >
                    Add Additional Installer
                </button>
            </div>

            <div className='flex flex-col mb-6'>
                <label className="text-xs font-semibold text-gray-700 mb-1">Gutter Guard</label>
                <div className="text-gray-900 text-sm">{gutterGuardFt}</div>
            </div>

            {/* Channel Settings */}
            <div className="mb-6">
                <label className="text-xs font-semibold text-gray-700 mb-2 block">Channel</label>
                {channel.map((ch, index) => (
                    <div key={index} className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-2">
                        <div className="flex flex-col">
                            <label className="text-xs font-semibold text-gray-700 mb-1">Footage</label>
                            <input
                                type="number"
                                defaultValue={ch.ft ?? ''}
                                onBlur={(e) => handleChannelChange(index, 'ft', parseInt(e.target.value) || null)}
                                className="w-full max-w-xs px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 text-sm"
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="text-xs font-semibold text-gray-700 mb-1">Type</label>
                            <select
                                value={ch.type ?? ''}
                                onChange={(e) => handleChannelChange(index, 'type', e.target.value === '' ? null : e.target.value)}
                                className="w-full max-w-xs px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 text-sm"
                            >
                                <option value="">Select...</option>
                                {Object.values(ChannelType).map((type, i) => (
                                    <option key={i} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>

                        <div className="flex flex-col">
                            <label className="text-xs font-semibold text-gray-700 mb-1">Color</label>
                            <select
                                value={ch.color ?? ''}
                                onChange={(e) => handleChannelChange(index, 'color', e.target.value === '' ? null : e.target.value)}
                                className="w-full max-w-xs px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 text-sm"
                            >
                                <option value="">Select...</option>
                                {channelColors.current.map((color, i) => (
                                    <option key={i} value={color}>{color}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                ))}
            </div>

            {/* Notes Section */}
            <div className="mb-6">
                <label className="text-xs font-semibold text-gray-700 mb-2 block">Notes</label>
                <textarea
                    className="w-full max-w-2xl px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 text-sm"
                    placeholder="Enter notes here..."
                    onBlur={(e) => handleNotesChange(e, row)}
                    defaultValue={row.original.notes ?? ''}
                />
            </div>
        </div>
    );
}
