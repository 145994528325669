import axios from "axios";
import { BACKEND_URL } from "../environment";
import { Filters, IPaginatedResourceResponse, IPaginationRequest, Query, serializeQuery, tryDecodePaginationResponseData } from "@common.api";
import { IInstall, int, IUpdateInstallParams, IPandaDocLightingProposalTemplate, ICreateInstallParams } from "@common.abstractions";
import { getPaginationHeaders } from "./helpers/pagination-helpers";

export async function ManuallyCreateInstall(documentId: string, templateId?: string): Promise<IInstall | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/installs/`, { documentId, templateId }, { withCredentials: true });
        if (response.status === 201) return response.data as IInstall;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveInstall(installId: int): Promise<IInstall | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/installs/${installId}`, { withCredentials: true });
        if (response.status === 200) return response.data as IInstall;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveInstalls(query?: Query<IInstall>, pagination?: IPaginationRequest): Promise<IPaginatedResourceResponse<IInstall> | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/installs`, {
            params: query,
            paramsSerializer: (q: any) => serializeQuery(q),
            headers: getPaginationHeaders(pagination),
            withCredentials: true
        });

        if (response.status === 200) {
            return {
                pagination: tryDecodePaginationResponseData(response.headers['pagination']),
                values: response.data as IInstall[]
            }
        };
        console.log('Installs failed to load with response code: ', response.status);
        return undefined;
    } catch (err: any) {
        console.log('Loading installs threw an exception: ', err);
        return undefined;
    }
}

export async function UpdateInstall(installId: int, update: IUpdateInstallParams): Promise<IInstall | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/installs/${installId}`, update, { withCredentials: true });
        if (response.status === 200) return response.data as IInstall;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteInstall(installId: int): Promise<IInstall | undefined> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/installs/${installId}`, { withCredentials: true });
        if (response.status === 200) return response.data as IInstall;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function MarkInstallAsPaid(installId: int) {
    try {
        const response = await axios.post(`${BACKEND_URL}/installs/${installId}/paid`, undefined, { withCredentials: true });
        if (response.status === 200) return response.data as IInstall;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function SendWarrantyDoc(installId: int) {
    try {
        const response = await axios.post(`${BACKEND_URL}/installs/${installId}/sendWarrantyDoc`, undefined, { withCredentials: true });
        console.log("RESPNOSENOSENSE", response.data);
        if (response.status === 200) return response.data as IInstall;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function SendOutstandingBalanceInvoie(installId: int) {
    try {
        const response = await axios.post(`${BACKEND_URL}/installs/${installId}/sendOutstandingBalanceInvoice`, undefined, { withCredentials: true });
        console.log("RESPNOSENOSENSE", response.data);
        if (response.status === 200) return response.data as IInstall;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveInstallCount(filters: Filters<IInstall>): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/installs/count`, {
            params: { filters },
            paramsSerializer: (val: any) => serializeQuery(val),
            withCredentials: true,
        });
        if (response.status === 200) {
            return response.data;
        }

        console.log("Install count failed to load with response code: ", response.status);
        return undefined;
    } catch (err: any) {
        console.log("Loading installs count threw an exception: ", err);
        return undefined;
    }
}

export async function RetrieveInstallsByContactName(name: string, query?: Query<IInstall>): Promise<IInstall[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/installs/contacts/${name}`, { params: query, paramsSerializer: (val: any) => serializeQuery(val), withCredentials: true });
        if (response.status === 200)
            return response.data as IInstall[];

        console.log('Installs failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err) {
        console.log('Loading installs threw an exception: ', err);
        return undefined;
    }
}

export async function RetrievePandaDocTemplateRelationships(): Promise<IPandaDocLightingProposalTemplate[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/pandaDocLightingProposalTemplate/`, { withCredentials: true });
        if (response.status === 200) return response.data as IPandaDocLightingProposalTemplate[];
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function ManuallyCreateInstallFromContractorJob(params: ICreateInstallParams): Promise<IInstall | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/installs/contractorJob`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IInstall;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function sendNewSalesNotifcation(installId: int, sendAll?: boolean) {
    try {
        const response = await axios.post(`${BACKEND_URL}/installs/${installId}/sendNewSalesNotifcation`, { sendAll: sendAll }, { withCredentials: true });
        console.log("RESPNOSENOSENSE", response.data);
        if (response.status === 200) return true;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function MarkInstallAsJobCompleted(installId: int, jobCompletedDate: Date) {
    try {
        const response = await axios.post(`${BACKEND_URL}/installs/${installId}/markAsJobCompleted`, { jobCompletedDate: jobCompletedDate }, { withCredentials: true });
        if (response.status === 200) return response.data
        return undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
}

export async function UpdateInstallBookableUsers(installId: int, bookableUserIds: int[]) {
    try {
        const response = await axios.post(`${BACKEND_URL}/installs/${installId}/bookableUsers`, { bookableUserIds: bookableUserIds }, { withCredentials: true });
        if (response.status === 200) return response.data as IInstall
        return undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
}