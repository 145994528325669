import { Address, BuildingType, InstallationType, ProductCertification, ProductLine, int } from "../abstractions";

export function formatPhoneNumber(phoneNumber: string): string {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ")", match[3], "-", match[4]].join("");
    }
    return "";
}

export function cleanPhoneNumber(phoneNumber: string): string | undefined {
    const numbers = String(phoneNumber).match(/\d/g);
    if (!numbers) return undefined;

    if (numbers.length === 11) return numbers.slice(1, 11).join("");

    if (numbers.length === 10) return numbers.join("");

    return undefined;
}

export function dashFormatPhoneNumber(phoneNumber: string): string | undefined{
    const clean_phone = cleanPhoneNumber(phoneNumber);
    if(!clean_phone) {
        return undefined;
    }
    return clean_phone.substring(0,3).concat("-", clean_phone.substring(3,6), "-", clean_phone.substring(6,10));
}

export function parenthesesFormatPhoneNumber(phoneNumber: string): string {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ["(", match[2], ")", match[3], "-", match[4]].join("");
    }
    return "";
}

export function formatCloseRate(leads: number, customers: number): string {
    return ((customers / leads) * 100).toFixed(2) + "%";
}

export function formatWholeMoney(amount: number): string {
    return `$${new Intl.NumberFormat().format(Math.round(amount / 100))}`;
}

export function formatMoney(amount: number): string {
    let isNegative = amount < 0;

    amount = Math.abs(amount);
    const formatWithoutComma = (amount / 100).toFixed(2);
    const formatWithComma = formatWithoutComma.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //Adds comma to every 3 to the left of decimal

    if (isNegative) {
        return `-$${formatWithComma}`;
    } else {
        return `$${formatWithComma}`;
    }
}

export function capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function formatProductLine(productLine: ProductLine) {
    switch (productLine) {
        case ProductLine.CLASSIC:
            return "Classic";
        case ProductLine.COLOR_CHANGING:
            return "Color Changing";
    }
}

export function formatBuildingType(buildingType: BuildingType) {
    switch (buildingType) {
        case BuildingType.RESIDENTIAL:
            return "Residential";
        case BuildingType.COMMERCIAL:
            return "Commercial";
        case BuildingType.INDUSTRIAL:
            return "Industrial";
        case BuildingType.MUNICIPAL:
            return "Municipal";
        case BuildingType.SINGLE_FAMILY:
            return "Single Family";
        case BuildingType.CONDO_TOWNHOME:
            return "Condo/Townhome";
    }
}

export function formatProductCertification(productCertification: ProductCertification) {
    switch (productCertification) {
        case ProductCertification.PERMIT24V:
            return "24V Permit";
        case ProductCertification.PERMIT_PURPLE:
            return "permit_purple";
    }
}

export function formatInstallationType(installationType: InstallationType) {
    switch (installationType) {
        case InstallationType.PROFESSIONAL:
            return "Professional";
        case InstallationType.DIY:
            return "DIY";
    }
}

export function convertDate(date: Date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth() + 1).toString();
    var dd = date.getDate().toString();

    var mmChars = mm.split("");
    var ddChars = dd.split("");

    return yyyy + "-" + (mmChars[1] ? mm : "0" + mmChars[0]) + "-" + (ddChars[1] ? dd : "0" + ddChars[0]);
}

export function formatAddress(address: Address) {
    const { streetAddress, city, state, zip, country } = address;
    return `${streetAddress}, ${city}, ${state}, ${zip} ${country}`;
}


export function convertState(input: string, to: "abbr" | "name") {

    var states = [
        ['Arizona', 'AZ'],
        ['Alabama', 'AL'],
        ['Alaska', 'AK'],
        ['Arkansas', 'AR'],
        ['California', 'CA'],
        ['Colorado', 'CO'],
        ['Connecticut', 'CT'],
        ['Delaware', 'DE'],
        ['Florida', 'FL'],
        ['Georgia', 'GA'],
        ['Hawaii', 'HI'],
        ['Idaho', 'ID'],
        ['Illinois', 'IL'],
        ['Indiana', 'IN'],
        ['Iowa', 'IA'],
        ['Kansas', 'KS'],
        ['Kentucky', 'KY'],
        ['Louisiana', 'LA'],
        ['Maine', 'ME'],
        ['Maryland', 'MD'],
        ['Massachusetts', 'MA'],
        ['Michigan', 'MI'],
        ['Minnesota', 'MN'],
        ['Mississippi', 'MS'],
        ['Missouri', 'MO'],
        ['Montana', 'MT'],
        ['Nebraska', 'NE'],
        ['Nevada', 'NV'],
        ['New Hampshire', 'NH'],
        ['New Jersey', 'NJ'],
        ['New Mexico', 'NM'],
        ['New York', 'NY'],
        ['North Carolina', 'NC'],
        ['North Dakota', 'ND'],
        ['Ohio', 'OH'],
        ['Oklahoma', 'OK'],
        ['Oregon', 'OR'],
        ['Pennsylvania', 'PA'],
        ['Rhode Island', 'RI'],
        ['South Carolina', 'SC'],
        ['South Dakota', 'SD'],
        ['Tennessee', 'TN'],
        ['Texas', 'TX'],
        ['Utah', 'UT'],
        ['Vermont', 'VT'],
        ['Virginia', 'VA'],
        ['Washington', 'WA'],
        ['West Virginia', 'WV'],
        ['Wisconsin', 'WI'],
        ['Wyoming', 'WY'],
    ];

    if (to == 'abbr') {
        input = input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        for (let i = 0; i < states.length; i++) {
            if (states[i][0] == input) {
                return (states[i][1]);
            }
        }
    } else if (to == 'name') {
        input = input.toUpperCase();
        for (let i = 0; i < states.length; i++) {
            if (states[i][1] == input) {
                return (states[i][0]);
            }
        }
    }
}

export const formatDate = (date: Date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return formattedDate
}

export const formatDateStringWithDayOfWeek = (date: Date): string => {
    const month = String(date.getMonth()).padStart(2,'0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).padStart(2, '0');
    const dayOfWeek = getDayOfWeekString(date);

    return `${month}/${day}/${year} ${dayOfWeek}`;
};

export const getDayOfWeekString = (date: Date): string => {
    const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    return days[new Date(date).getDay()];
}

export const moneyStringToCents = (money: string): int => {
    if (money.includes(".")) {
        const split = money.split(".");
        if (split.length !== 2) {
            throw new Error("Failed to parse money string: " + money);
        }
        if (split[1].length > 2) {
            split[1] = split[1].substring(0, 2);
        }
        if (split[1].length < 2) {
            split[1] = split[1].padEnd(2, '0');
        }
        return int.parse(split.join(""));
    }
    return int(int.parse(money) * 100);
}
