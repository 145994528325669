import {
  float,
  Scope,
  IDiscountPackage,
  int,
  IOrder,
  IOrderItem,
  IProduct,
  IProductVariant,
  VariantAttribute,
  ProductCertification,
} from "@common.abstractions";
import { calculateDiscount, formatMoney } from "@common.tools";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@frontend/components/misc/modal";
import { CreateOrderItem, RetrieveOrder } from "@frontend/services/orders";
import {
  RetrieveProductCategories,
  RetrieveProducts,
} from "@frontend/services/products";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SideCart } from "../side-cart";

import "./_createOrder.scss";
import { Loading } from "@frontend/components/misc/loading";
import AuthContext from "@frontend/contexts/AuthContext";
import Button from "@frontend/components/misc/button/Button";
import { Page } from "@frontend/components/misc/layout";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import { TabBar2 } from "@frontend/components/misc/tab-bar-2";
import { useNavigate } from "react-router-dom-v5-compat";

type ShortProductCategory = { name: string; products: IProduct[] };
export default function CreateOrder() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [discountPackage, setDiscountPackage] = useState<
    IDiscountPackage | undefined
  >();
  const params = useParams<{ orderId: string }>();
  const [orderId] = useState<int>(int.parse(params.orderId));
  const [order, setOrder] = useState<IOrder>();

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] =
    useState<ShortProductCategory>();
  const [categories, setCategories] = useState<ShortProductCategory[]>([]);

  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [selectedCombo, setSelectedCombo] = useState<VariantAttribute[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<IProductVariant>();
  const [variantExists, setVariantExists] = useState<boolean>(true);
  const [selectedQty, setSelectedQty] = useState<int>(1 as int);
  const [newItem, setNewItem] = useState<IOrderItem>();

  const [dropdowns, setDropdowns] = useState<
    { name: string; values: string[] }[]
  >([]);
  const [hasVariants, setHasVariants] = useState<boolean>(false);

  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [sideCartVisibility, setSideCartVisibility] = useState<boolean>(false);
  const [sideCartInitialLoad, setSideCartInitialLoad] =
    useState<boolean>(false);

  const loadOrder = async () => {
    const tempOrder = await RetrieveOrder(orderId);
    if (tempOrder) {
      setOrder(tempOrder);
      return;
    }
    toast.error("Error Loading Orders");
  };

  const setTab = (name: string) => {
    const tabs = categories.filter((el) => el.name === name);

    setSelectedCategory(tabs[0]!);
  };

  const loadProductCategories = async () => {
    const tempProducts = await RetrieveProducts({
      relations: ["variants"],
    });

    let filteredProducts = await tempProducts?.filter((product) => {
      if (!product.permit24V && !product.permitPurple) {
        return true;
      } else {
        var permit: boolean = false;

        if(product.permit24V) {
          if (authContext?.user?.company?.productCertifications?.includes(ProductCertification.PERMIT24V)) {
            permit = true;
          } else {
            permit = false;
            return false;
          }
        }
        if(product.permitPurple) {
            if(authContext?.user?.company?.productCertifications?.includes(ProductCertification.PERMIT_PURPLE)) {
              permit &&= permit;
            } else {
              permit = false;
            }
        }
        return permit;
      }

    })
    if (!filteredProducts) {
      setLoading(false);
      return;
    }

    const allCategory: ShortProductCategory = {
      name: "All",
      products: filteredProducts,
    };

    const tempProductCategories = (await RetrieveProductCategories({
      relations: ["products", "products.variants"],
    })) as ShortProductCategory[] | undefined;

    if (!tempProductCategories) {
      console.log("failed to load product categories");
      setLoading(false);
      return;
    }

    for (const category of tempProductCategories) {
      category.products = category.products.filter((product) => {
        if (!product.permit24V) {
          return true;
        } else {
          if (authContext?.user?.company?.productCertifications?.includes(ProductCertification.PERMIT24V)) {
            return true;
          } else {
            return false;
          }
        }
      });
    }
    const finalCategories = [allCategory, ...tempProductCategories];

    setCategories(finalCategories);
    setSelectedCategory(allCategory);
    setLoading(false);
  };

  const initializeDiscountPackage = () => {
    if (authContext?.user?.company?.packages) {
      setDiscountPackage(
        authContext!.user!.company!.packages![
        authContext!.user!.company!.packages!.length - 1
        ]
      );
    }
  };

  const openModal = (product: IProduct) => {
    setSelectedProduct(product);
    checkHasVariants(product);
    setModalVisibility(true);
  };

  const checkHasVariants = (product: IProduct) => {
    if (product && product.variants) {
      setSelectedVariant(product.variants[0] as IProductVariant);

      if (product.variants.length === 1) {
        if ((product.variants[0] as IProductVariant).attributes.length === 0) {
          setHasVariants(false);
        } else {
          createVariantDropdowns(product);
          setHasVariants(true);
        }
      } else {
        createVariantDropdowns(product);
        setHasVariants(true);
      }
    }
  };

  const sortMonths = (months: string[]) => {
    const monthOrder = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return months.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));
  }

  const sortAlphabetically = (arr: string[]) => {
    return arr.sort((a, b) => a.localeCompare(b));
  }

  const sortDropdownValues = (dropdowns: { name: string; values: string[] }[]) => {
    return dropdowns.map(dropdown => ({
      ...dropdown,
      values: dropdown.name === "Month" ? sortMonths(dropdown.values) : sortAlphabetically(dropdown.values)
    }));
  };

  const createVariantDropdowns = (product: IProduct) => {
    let tempDropdowns: { name: string; values: string[] }[] = [];
    (product!.variants as IProductVariant[]).forEach((el, i) => {
      el.attributes.forEach((attr, index) => {
        if (!tempDropdowns[index]) {
          //Add the name to the object at array[i] if that index doesn't exist yet
          tempDropdowns.push({ name: attr.name, values: [] });
        }
        if (!tempDropdowns[index].values.some((val) => val === attr.value)) {
          //push the value to the values array in the object at array[i] if doesn't exist already
          tempDropdowns[index].values.push(attr.value);
        }
      });
    });

    // tempDropdowns = sortDropdownValues(tempDropdowns);

    setDropdowns(tempDropdowns);

    let tempSelectedCombo: { name: string; value: string }[] = [];

    tempDropdowns.forEach((el, i) => {
      if (!tempSelectedCombo[i]) {
        tempSelectedCombo.push({ name: el.name, value: el.values[0] });
      } else {
        tempSelectedCombo[i].name = el.name;
        tempSelectedCombo[i].value = el.values[0];
      }
    });

    setSelectedCombo(tempSelectedCombo);
  };

  const handleComboChange = (value: string, i: number) => {
    let tempSelectedCombo = [...selectedCombo];
    tempSelectedCombo[i].value = value;
    setSelectedCombo(tempSelectedCombo);
    let variantExists: boolean = false;

    //Checks if the tempSelected Combo exists as a variant
    (selectedProduct!.variants as IProductVariant[]).find((el) => {
      if (el.attributes.length === tempSelectedCombo.length) {
        if (
          !el.attributes.some(
            (val, index) => val.value !== tempSelectedCombo[index].value
          )
        ) {
          setSelectedVariant(el);
          variantExists = true;
        }
      }
    });

    setVariantExists(variantExists);
  };

  const handleSideCartActivation = () => {
    setSideCartVisibility(true);
    setSideCartInitialLoad(true);
  };

  const handleAddToCart = async () => {
    if (!selectedVariant) return;

    const orderItemRes = await CreateOrderItem(
      orderId,
      selectedQty,
      selectedVariant.id
    );

    if (orderItemRes) {
      setNewItem(orderItemRes);
      setSelectedQty(1 as int);
    } else {
      toast.error("Error adding product");
      return;
    }

    setModalVisibility(false);
    setSideCartInitialLoad(true);
    if (!sideCartInitialLoad) {
      setSideCartVisibility(true);
    }
  };

  const handleShippingOptions = () => {
    if (authContext?.user?.company?.id !== order?.lead.company?.id) {
      toast.error('Cannot add shipping options for another company');
      return;
    }

    navigate(`/orders/${orderId}/shipping/`);
  }

  useEffect(() => {
    loadProductCategories();
    loadOrder();
    initializeDiscountPackage();
  }, []);

  return (
    <Page>
      <SideCart
        visible={sideCartVisibility}
        addedItem={newItem}
        orderId={orderId}
        closeSideCart={() => setSideCartVisibility(false)}
        openSideCart={() => handleSideCartActivation()}
        loaded={sideCartInitialLoad}
      />
      {selectedProduct ? (
        <Modal
          key={selectedProduct.id}
          closeModal={() => {
            setModalVisibility(false)
            setSelectedQty(1 as int)
          }}
          visible={modalVisibility}
        >
          <div className="p-8 max-w-6xl">
            <div>
              <h3 className="text-xl text-gray-600 text-center mb-3">
                {selectedProduct.title}
              </h3>
            </div>
            {selectedVariant?.description ? (
              <div className="text-sm text-gray-500 mb-3 overflow-y-auto max-h-96">
                {selectedVariant.description}
              </div>
            ) : (
              <div className="text-sm text-gray-500 mb-3 overflow-y-auto max-h-96">
                {selectedProduct.description}
              </div>
            )}
            {hasVariants ? (
              <div className="flex mb-3">
                {dropdowns.map((el, i) => {
                  return (
                    <div
                      className="flex flex-col mr-5"
                      key={`${el.name} + ${el.values.join("")} `}
                    >
                      <label className="text-gray-500 text-sm">{el.name}</label>
                      <select
                        className="text-gray-600 text-md border-gray-300 p-1 border"
                        onChange={(e) => handleComboChange(e.target.value, i)}
                      >
                        {el.values.map((el) => {
                          return (
                            <option key={el} value={el}>
                              {el}
                            </option>
                          );
                        })}
                      </select>
                      {!variantExists ? (
                        <div className="text-theme_red text-sm">
                          Variant is not available
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}

            <div className="flex flex-col mb-3 w-20">
              <label className="text-gray-500 text-sm">Amount</label>
              <input
                min="1"
                type="number"
                className="text-gray-600 text-md border-gray-300 p-1 border"
                value={selectedQty}
                onChange={(e) => setSelectedQty(int.parse(e.target.value))}
              />
            </div>

            <div className="flex flex-col mb-3">
              <label className="text-gray-500">Cost</label>
              {discountPackage ? (
                selectedVariant?.retailPrice ? (
                  <>
                    <div className="product_cost text-gray-500">
                      {formatMoney(
                        calculateDiscount(
                          selectedVariant.retailPrice,
                          discountPackage.discountPercent
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="product_cost text-gray-500">
                      {formatMoney(
                        calculateDiscount(
                          selectedProduct.retailPrice,
                          discountPackage.discountPercent
                        )
                      )}
                    </div>
                  </>
                )
              ) : selectedVariant?.retailPrice ? (
                <>
                  <div className="product_cost text-gray-500">
                    {formatMoney(selectedVariant.retailPrice)}
                  </div>
                </>
              ) : (
                <>
                  <div className="product_cost text-gray-500">
                    {formatMoney(selectedProduct.retailPrice)}
                  </div>
                </>
              )}
            </div>

            <div className="flex mb-3 items-end justify-between">
              <div className="flex flex-col">
                <label className="text-gray-500">Total</label>
                <div className="flex text-gray-800 text-xl">
                  <div>
                    {discountPackage
                      ? selectedVariant?.retailPrice
                        ? formatMoney(
                          calculateDiscount(
                            float(selectedVariant.retailPrice * selectedQty),
                            discountPackage.discountPercent
                          )
                        )
                        : formatMoney(
                          calculateDiscount(
                            float(selectedProduct.retailPrice * selectedQty),
                            discountPackage.discountPercent
                          )
                        )
                      : selectedVariant?.retailPrice
                        ? formatMoney(selectedVariant.retailPrice * selectedQty)
                        : formatMoney(selectedProduct.retailPrice * selectedQty)}
                  </div>
                </div>
              </div>

              <div
                className="btn bg-theme_blue ml-4 text-white text-sm"
                onClick={handleAddToCart}
              >
                Add to Cart
              </div>
            </div>
          </div>
        </Modal>
      ) : null}

      <TopBar
        title="Order Creation"
        subtitle={authContext?.hasAnyGrant(Scope.Order.READ_ANY) ? order?.lead.company?.name : `${order?.lead.quoteRequest?.contact?.firstname} ${order?.lead.quoteRequest?.contact?.lastname}`}
      >
        {order?.lead ? (
          <Button
            type="button"
            color="blue"
            style="outline"
            rounded
            className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4"
            onClick={() => navigate(`/leads/${order.lead.id}`)}
          >
            View Lead
          </Button>
        ) : null}
        <Button
          className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4"
          type="button"
          color="blue"
          style="solid"
          rounded
          onClick={handleShippingOptions}
        >
          Continue to Shipping Options
          <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
        </Button>
      </TopBar>

      {renderContent(loading, categories, selectedCategory, setTab, openModal, discountPackage)}
    </Page>
  );
}

//THIS IS THE PRODUCT INFO WE DELETED, but we can use later if necesary
{
  /* <div className="product_info">
                            <div>
                                Weight:
                                {selectedVariant?.weight
                                    ? selectedVariant.weightUnit
                                        ? selectedVariant.weight !== 1
                                            ? selectedVariant.weightUnit !== "g" && selectedVariant.weightUnit !== "oz"
                                                ? `${selectedVariant.weight} ${selectedVariant.weightUnit}s`
                                                : `${selectedVariant.weight} ${selectedVariant.weightUnit}`
                                            : null
                                        : selectedVariant.weight !== 1
                                        ? selectedVariant.weightUnit !== "g" && selectedProduct.weightUnit !== "oz"
                                            ? `${selectedVariant.weight} ${selectedProduct.weightUnit}s`
                                            : `${selectedVariant.weight} ${selectedProduct.weightUnit}`
                                        : null
                                    : selectedProduct.weight !== 1
                                    ? selectedProduct.weightUnit !== "g" && selectedProduct.weightUnit !== "oz"
                                        ? `${selectedProduct.weight} ${selectedProduct.weightUnit}s`
                                        : `${selectedProduct.weight} ${selectedProduct.weightUnit}`
                                    : null}
                            </div>
                            <div>
                                {selectedVariant?.length ? (
                                    selectedVariant?.dimUnit ? (
                                        <div>Length: {`${selectedVariant.length} ${selectedVariant.dimUnit}`}</div>
                                    ) : (
                                        <div>Length: {`${selectedVariant.length} ${selectedProduct.dimUnit}`}</div>
                                    )
                                ) : (
                                    <div>Length: {`${selectedProduct.length} ${selectedProduct.dimUnit}`}</div>
                                )}
                            </div>
                            <div>
                                {selectedVariant?.width ? (
                                    selectedVariant?.dimUnit ? (
                                        <div>Width: {`${selectedVariant.width} ${selectedVariant.dimUnit}`}</div>
                                    ) : (
                                        <div>Width: {`${selectedVariant.width} ${selectedProduct.dimUnit}`}</div>
                                    )
                                ) : (
                                    <div>Width: {`${selectedProduct.width} ${selectedProduct.dimUnit}`}</div>
                                )}
                            </div>
                            <div>
                                {selectedVariant?.height ? (
                                    selectedVariant?.dimUnit ? (
                                        <div>Height: {`${selectedVariant.height} ${selectedVariant.dimUnit}`}</div>
                                    ) : (
                                        <div>Height: {`${selectedVariant.height} ${selectedProduct.dimUnit}`}</div>
                                    )
                                ) : (
                                    <div>Height: {`${selectedProduct.height} ${selectedProduct.dimUnit}`}</div>
                                )}
                            </div>
                        </div> */
}

function renderContent(loading: boolean, categories: ShortProductCategory[], selectedCategory: ShortProductCategory | undefined, setTab: (name: string) => void
  , openModal: (product: IProduct) => void, discountPackage: IDiscountPackage | undefined): any {
  if (loading) {
    return <Loading center />
  }

  return (
    <TabBar2
      tabOptions={{
        tabs: categories.map((c) => {
          return { name: c.name };
        }),
        active: selectedCategory ? selectedCategory.name : "none",
        onClick: setTab,
      }}
    >
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2 md:gap-4 overflow-y-auto">
        {renderProducts(selectedCategory, openModal, discountPackage)}
      </div>
    </TabBar2>
  );

  function renderProducts(selectedCategory: ShortProductCategory | undefined, openModal: (product: IProduct) => void, discountPackage: IDiscountPackage | undefined) {
    if (!selectedCategory) {
      return (<div>Error failed loading products! Please try again later.</div>);
    }

    if (selectedCategory.products.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full text-center p-4 bg-gray-100 text-gray-500">
          <p className="text-sm sm:text-base md:text-lg">
            There are no products in this category. Please check again later.
          </p>
        </div>
      );
    }

    const listedProducts = selectedCategory.products.filter(p => p.listed);
    listedProducts.sort((a, b) => a.title.localeCompare(b.title));

    return listedProducts.map((el) => {
      return (
        <div
          key={el.id}
          className="flex flex-col h-40 sm:h-44 md:h-52 items-center justify-center bg-white cursor-pointer shadow-md hover:shadow-lg duration-200"
          onClick={() => openModal(el)}
        >
          <div className="title_container mb-2 sm:mb-3 md:mb-4 px-4">
            <h3 className="text-sm sm:text-base md:text-lg text-gray-500 text-center">
              {el.title}
            </h3>
          </div>

          {discountPackage ? (
            <>
              <div className="text-gray-400 text-center line-through">
                {formatMoney(el.retailPrice)}
              </div>
              <div className="text-center text-gray-600 text-sm sm:text-base md:text-xl">
                {formatMoney(
                  calculateDiscount(
                    el.retailPrice,
                    discountPackage.discountPercent
                  )
                )}
              </div>
            </>
          ) : (
            <div className="text-center text-gray-600 text-sm sm:text-base md:text-xl">
              {formatMoney(el.retailPrice)}
            </div>
          )}
          <Button
            className="mt-4"
            rounded
            style="outline"
            color="blue"
          >
            Select Options{" "}
            <FontAwesomeIcon
              className="ml-2 text-xs"
              icon={faChevronRight}
            />
          </Button>
        </div>
      );
    });
  }
}