import { faChevronLeft, faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./_editProduct.scss";
import "../variant-product/_variantProduct.scss";
import "../../misc/form-slider/_formSlider.scss";

import { ArchiveProduct, CreateProduct as CreateProductService, RetrieveProduct, RetrieveProductCategories, RetrieveShippingGroups, RetrieveProductImageBlob } from "../../../services/products";
import { toast } from "react-toastify";
import { cartesianProduct } from "@common.tools";
import { VariantProduct } from "../variant-product";
import { useParams } from "react-router-dom";
import { Loading } from "@frontend/components/misc/loading";
import {MultiSelect} from "react-multi-select-component";
import {
    DimensionalUnit,
    float,
    ICreateProductParams,
    ICreateProductVariantParams,
    int,
    IProduct,
    IProductCategory,
    IProductImage,
    IShippingGroup,
    VariantAttribute,
    WeightUnit,
} from "@common.abstractions";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import { Page } from "@frontend/components/misc/layout";
import Button from "@frontend/components/misc/button/Button";
import { useNavigate } from "react-router-dom-v5-compat";
import ProductImageUpload from "@frontend/components/products/product-image/ProductImageUpload";
import CreateProductImage from "@frontend/services/upload-product-image";

export default function EditProduct() {
    const navigate= useNavigate();
    const [activeSlide, setActiveSlide] = useState<number>(1); //default slide 1

    const params = useParams<{ productId: string }>();
    const [productId] = useState<int>(int.parse(params.productId));

    const [mainProduct, setMainProduct] = useState<ICreateProductParams>({
        title: "",
        description: "",
        weightUnit: "oz" as WeightUnit,
        weight: float(0),
        dimUnit: "in" as DimensionalUnit,
        length: float(0),
        height: float(0),
        width: float(0),
        retailPrice: float(0),
        declaredValue: float(0),
        listed: true,
        moqValue: 1 as float,
        permit24V: false,
        permitPurple: false,
        guid: ""
    });
    const [productTitle, setProductTitle] = useState<string>("");
    const [productDescription, setProductDescription] = useState<string>("");
    const [productWeightUnit, setProductWeightUnit] = useState<IProduct["weightUnit"]>("oz" as WeightUnit);
    const [productDimUnit, setProductDimUnit] = useState<IProduct["dimUnit"]>("in" as DimensionalUnit);
    const [productWeight, setProductWeight] = useState<float>();
    const [productHeight, setProductHeight] = useState<float>();
    const [productWidth, setProductWidth] = useState<float>();
    const [productLength, setProductLength] = useState<float>();
    const [productRetailPrice, setProductRetailPrice] = useState<float>();
    const [productDeclaredValue, setProductDeclaredValue] = useState<float>();
    const [productListed, setProductListed] = useState<boolean>(true);
    const [productMOQValue, setProductMOQValue] = useState<float>();
    const [productPermit24V, setProductPermit24V] = useState<boolean>(false);
    const [productPermitPurple, setProductPermitPurple] = useState<boolean>(false);
    const [productGuid, setProductGuid] = useState<string>("");
    const [productCategories, setProductCategories] = useState<IProductCategory[]>([]); //default category
    const [productShippingGroup, setProductShippingGroup] = useState<IShippingGroup | null>(null); //default shipping group
    const [productImage, setProductImage] = useState<IProductImage | null>(null);
    const [shippingGroups, setShippingGroups] = useState<IShippingGroup[]>(); //default shipping group
    const [dropDownCategories, setDropdownCategories] = useState<{ value: any; label: string }[]>();
    const [dropDownSelected, setDropdownSelected] = useState<{ value: any; label: string }[]>();
    const [imageSrc, setImageSrc] = useState<{src: any} | undefined>();

    const loadProduct = async () => {
        const response = await RetrieveProduct(productId);
        if (!response) {
            toast.error("Error loading product");
            return;
        }

        setProductDeclaredValue(float.parse((response.declaredValue / 100).toFixed(2)));
        setProductRetailPrice(float.parse((response.retailPrice / 100).toFixed(2)));
        setProductListed(response.listed);
        setProductMOQValue(response.moqValue);
        setProductTitle(response.title);
        setProductDescription(response.description);
        setProductLength(response.length);
        setProductWidth(response.width);
        setProductDimUnit(response.dimUnit);
        setProductHeight(response.height);
        setProductWeight(response.weight);
        setProductWeightUnit(response.weightUnit);
        setProductPermit24V(response.permit24V);
        setProductPermitPurple(response.permitPurple);
        setProductImage(response.image as IProductImage);
        setProductGuid(response.guid);

        //setDropdownSelected
        const tempDropdownSelected = response.categories?.map((el) => {
            return {
                value: el.id,
                label: el.name,
            };
        });

        setDropdownSelected(tempDropdownSelected ?? []);
        populateAttributesForEditing(response);
        loadImages(response.image);
    };

    const loadProductCategories = async () => {
        //LOAD PRODUCT CATEGORIES
        const response = await RetrieveProductCategories();

        //THEN ASSIGN productShippingGroup to the first element from response
        if (response) {
            const tempDropDownCategories = response.map((el) => {
                return { value: el.id, label: el.name };
            });
            setDropdownCategories(tempDropDownCategories);
            setDropdownSelected([]);
        }
    };

    const loadShippingGroups = async () => {
        //LOAD SHIPPING GROUPS
        const response = await RetrieveShippingGroups();

        //THEN ASSIGN productShippingGroup to the first element from response
        if (response) {
            setShippingGroups(response);
        }
    };

    //PREPOPULATE KEY VALUE ROWS AND PAIRS FOR PRODUCT EDITING (SLIDE 2)
    const populateAttributesForEditing = (product: IProduct) => {
        if (!product.variants || !product.variants[0].attributes.length) return;

        // const [keyValueRows, setKeyValueRows] = useState<number>(1);
        // // const [keyValuePairs, setKeyValuePairs] = useState<{ name: string; value: string }[]>([{ name: "", value: "" }]);

        //Just get the attribute name. Each variant has each attribute.
        let tempKeyValuePairsBeforeFormatting: { name: string; values: string[] }[] = [];
        let tempKeyValueRows = 0;
        product.variants[0].attributes.forEach((el) => {
            tempKeyValueRows++;
            tempKeyValuePairsBeforeFormatting.push({ name: el.name, values: [] });
        });

        let tempKeyValuePairs: { name: string; value: string }[] = [];
        product.variants.forEach((el) => {
            el.attributes.forEach((attr) => {
                const tempKeyValuePair = tempKeyValuePairsBeforeFormatting.find((el) => el.name === attr.name);

                if (tempKeyValuePair) {
                    if (!tempKeyValuePair.values.includes(attr.value)) {
                        tempKeyValuePair.values.push(attr.value);
                    }
                }
            });
        });

        tempKeyValuePairs = tempKeyValuePairsBeforeFormatting.map((el) => {
            return { name: el.name, value: el.values.join(", ") };
        });

        setKeyValueRows(tempKeyValueRows);
        setKeyValuePairs(tempKeyValuePairs);
    };

    useEffect(() => {
        loadShippingGroups();
        loadProductCategories();
        loadProduct();
    }, []);

    interface ILooseObject {
        [key: string]: any;
    }
    const [formError, setFormError] = useState<ILooseObject>({ error: undefined });

    /*For First Slide*/
    const handleProductMOQValueChange = (value: float | undefined) => {
        let tempFormError = { ...formError };

        if (value || value === 0) {
            if (tempFormError.error) {
                delete tempFormError.error.productMOQValue;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error ? (tempFormError.error.productMOQValue = "required") : (tempFormError = { error: { productMOQValue: "required" } });
        }

        setProductMOQValue(value);
        setFormError(tempFormError);
    };
    const handleProductShippingGroupChange = (value: int) => {
        console.log(shippingGroups, value);
        if (shippingGroups) {
            if (!value) {
                setProductShippingGroup(null);
            } else {
                const tempShippingGroup = [...shippingGroups].find((el) => el.id === value);
                if (tempShippingGroup) {
                    setProductShippingGroup(tempShippingGroup);
                    return;
                }
            }
            return;
        }

        toast.error("Couldn't find a shipping group");
    };
    const handleProductTitleChange = (value: string) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productTitle;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error ? (tempFormError.error.productTitle = "required") : (tempFormError = { error: { productTitle: "required" } });
        }

        setFormError(tempFormError);
        setProductTitle(value);
    };
    const handleProductWeightUnitChange = (value: IProduct["weightUnit"]) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productWeightUnitChange;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error
                ? (tempFormError.error.productWeightUnitChange = "required")
                : (tempFormError = {
                      error: { productWeightUnitChange: "required" },
                  });
        }

        setFormError(tempFormError);
        setProductWeightUnit(value);
    };
    const handleProductDimUnitChange = (value: IProduct["dimUnit"]) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productDimUnit;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error ? (tempFormError.error.productDimUnit = "required") : (tempFormError = { error: { productDimUnit: "required" } });
        }

        setFormError(tempFormError);
        setProductDimUnit(value);
    };
    const handleProductWeightChange = (value: float | undefined) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productWeight;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error ? (tempFormError.error.productWeight = "required") : (tempFormError = { error: { productWeight: "required" } });
        }

        setProductWeight(value);
        setFormError(tempFormError);
    };
    const handleProductLengthChange = (value: float | undefined) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productLength;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error ? (tempFormError.error.productLength = "required") : (tempFormError = { error: { productLength: "required" } });
        }

        setProductLength(value);
        setFormError(tempFormError);
    };
    const handleProductHeightChange = (value: float | undefined) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productHeight;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error ? (tempFormError.error.productHeight = "required") : (tempFormError = { error: { productHeight: "required" } });
        }

        setProductHeight(value);
        setFormError(tempFormError);
    };
    const handleProductWidthChange = (value: float | undefined) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productWidth;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error ? (tempFormError.error.productWidth = "required") : (tempFormError = { error: { productWidth: "required" } });
        }

        setProductWidth(value);
        setFormError(tempFormError);
    };
    const handleProductRetailPriceChange = (value: float | undefined) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productRetailPrice;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error
                ? (tempFormError.error.productRetailPrice = "required")
                : (tempFormError = {
                      error: { productRetailPrice: "required" },
                  });
        }

        setProductRetailPrice(value);
        setFormError(tempFormError);
    };
    const handleProductDeclaredValue = (value: float | undefined) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.productDeclaredValue;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error
                ? (tempFormError.error.productDeclaredValue = "required")
                : (tempFormError = {
                      error: { productDeclaredValue: "required" },
                  });
        }

        setProductDeclaredValue(value);
        setFormError(tempFormError);
    };
    const createProduct = () => {
        setMainProduct({
            title: productTitle!,
            description: productDescription!,
            weightUnit: productWeightUnit!,
            weight: productWeight!,
            dimUnit: productDimUnit!,
            length: productLength!,
            height: productHeight!,
            width: productWidth!,
            retailPrice: productRetailPrice!,
            declaredValue: productDeclaredValue!,
            listed: productListed!,
            moqValue: productMOQValue!,
            permit24V: productPermit24V!,
            permitPurple: productPermitPurple!,
            guid: productGuid!,
        });
    };
    const validateForm = () => {
        let tempFormError: ILooseObject = {};
        if (!productTitle || productTitle.length === 0) {
            tempFormError.error ? (tempFormError.error.productTitle = "required") : (tempFormError = { error: { productTitle: "required" } });
        }
        if (productWeight === undefined || (productWeight && productWeight < 0)) {
            tempFormError.error
                ? (tempFormError.error.productWeight = "required")
                : (tempFormError = {
                      error: { productWeight: "required" },
                  });
        }
        if (productHeight === undefined || (productHeight && productHeight < 0)) {
            tempFormError.error
                ? (tempFormError.error.productHeight = "required")
                : (tempFormError = {
                      error: { productHeight: "required" },
                  });
        }
        if (productWidth === undefined || (productWidth && productWidth < 0)) {
            tempFormError.error ? (tempFormError.error.productWidth = "required") : (tempFormError = { error: { productWidth: "required" } });
        }
        if (productLength === undefined || (productLength && productLength < 0)) {
            tempFormError.error
                ? (tempFormError.error.productLength = "required")
                : (tempFormError = {
                      error: { productLength: "required" },
                  });
        }
        if (!productWeightUnit || productWeightUnit.length === undefined) {
            tempFormError.error
                ? (tempFormError.error.productWeightUnit = "required")
                : (tempFormError = {
                      error: { productWeightUnit: "required" },
                  });
        }
        if (!productDimUnit || productDimUnit.length === undefined) {
            tempFormError.error
                ? (tempFormError.error.productDimUnit = "required")
                : (tempFormError = {
                      error: { productDimUnit: "required" },
                  });
        }
        if (productDeclaredValue === undefined || (productDeclaredValue && productDeclaredValue < 0)) {
            tempFormError.error
                ? (tempFormError.error.productDeclaredValue = "required")
                : (tempFormError = {
                      error: { productDeclaredValue: "required" },
                  });
        }
        if (productRetailPrice === undefined || (productRetailPrice && productRetailPrice < 0)) {
            tempFormError.error
                ? (tempFormError.error.productRetailPrice = "required")
                : (tempFormError = {
                      error: { productRetailPrice: "required" },
                  });
        }
        if (productMOQValue === undefined || (productMOQValue && productMOQValue < 0)) {
            tempFormError.error
                ? (tempFormError.error.productMOQValue = "required")
                : (tempFormError = {
                      error: { productMOQValue: "required" },
                  });
        }
        setFormError(tempFormError);

        if (tempFormError.error) return false; //theres an error
        return true; //no errors
    };
    const handleCreateVariantsButton = () => {
        if (validateForm()) {
            createProduct();
            setActiveSlide(activeSlide + 1);
        }
    };
    /*End First Slide*/

    /*For Second Slide */
    const [keyValueRows, setKeyValueRows] = useState<number>(1);
    const [keyValuePairs, setKeyValuePairs] = useState<{ name: string; value: string }[]>([{ name: "", value: "" }]);
    const [combinations, setCombinations] = useState<VariantAttribute[][]>([]);

    const addAttributeRow = () => {
        let tempKeyValuePairs = [...keyValuePairs];
        tempKeyValuePairs.push({ name: "", value: "" });
        setKeyValuePairs(tempKeyValuePairs);
        setKeyValueRows(keyValueRows + 1);
    };
    const handleDeleteKeyValue = (index: number) => {
        let tempKeyValuePairs = [...keyValuePairs];
        if (index > -1) {
            tempKeyValuePairs.splice(index, 1);
        }

        setKeyValuePairs(tempKeyValuePairs);
        setKeyValueRows(keyValueRows - 1);
    };
    const validateAttributes = () => {
        let validated = true;

        if (keyValuePairs.length === 0) {
            validated = false;
        }

        keyValuePairs.some((el, i) => {
            for (const [key, value] of Object.entries(el)) {
                if (key.length === 0) {
                    validated = false; //if finds error
                    return;
                }
                if (value.length === 0) {
                    validated = false; //if finds error
                    return;
                }
            }
        });

        return validated;
    };
    const handleAttributeChange = (val: string, i: number) => {
        let tempKeyValuePairs = [...keyValuePairs];
        tempKeyValuePairs[i].name = val;
        setKeyValuePairs(tempKeyValuePairs);
    };
    const handleValueChange = (val: string, i: number) => {
        let tempKeyValuePairs = [...keyValuePairs];
        tempKeyValuePairs[i].value = val;
        setKeyValuePairs(tempKeyValuePairs);
    };
    const createVariants = (variantArrays: VariantAttribute[][]) => {
        let tempVariants: ICreateProductVariantParams[] = [];

        variantArrays.map((el) => {
            let tempAttrArr: VariantAttribute[] = [];
            el.map((attr) => {
                tempAttrArr.push({ name: attr.name, value: attr.value });
            });
            tempVariants.push({
                attributes: tempAttrArr,
                listed: productListed,
                description: productDescription,
                weightUnit: productWeightUnit,
                dimUnit: productDimUnit,
                weight: productWeight,
                height: productHeight,
                length: productLength,
                width: productWidth,
                retailPrice: productRetailPrice,
                declaredValue: productDeclaredValue,
            });
        });

        setVariants(tempVariants);
    };
    const generateCombinations = () => {
        if (validateAttributes()) {
            const tempCombinations = keyValuePairs.map((el) => {
                return el.value.split(",").map((value) => ({
                    name: el.name.trim(),
                    value: value.trim(),
                }));
            });

            const variants = cartesianProduct(tempCombinations);

            setCombinations(variants); //for slide 3 rows
            createVariants(variants); //for object that goes inside product variants object
            setRenderSlide3(true);
            setActiveSlide(activeSlide + 1);
            return;
        }
        toast.error("No empty attributes or values");
    };
    /*End Second Slide */

    /*For Third Slide */
    const [variants, setVariants] = useState<ICreateProductVariantParams[]>([]);
    const [renderSlide3, setRenderSlide3] = useState(false);
    const deleteVariant = (index: number) => {
        setRenderSlide3(false);
        let tempCombinations = [...combinations];
        let tempVariants = [...variants];

        console.log("Before: Combinations", tempCombinations);
        console.log("Before: Variants", tempVariants);

        tempCombinations = tempCombinations.filter((el, i) => i !== index);
        tempVariants = tempVariants.filter((el, i) => i !== index);

        console.log("Combinations", tempCombinations);
        console.log("Variants", tempVariants);

        setCombinations(tempCombinations);
        setVariants(tempVariants);

        setRenderSlide3(true);
    };
    const handleVariantChanges = (i: number, value: string | number | boolean, property: keyof ICreateProductVariantParams) => {
        let tempVariants = [...variants];

        if (tempVariants[i]) {
            let selectedVariant = tempVariants[i];
            (selectedVariant as any)[property] = value;
            setVariants(tempVariants);
        }
    };
    const handleVariationSubmit = async () => {
        let tempVariants = [...variants];

        for (const [key, value] of Object.entries(mainProduct)) {
            variants.map((el) => {
                if (key !== "listed") {
                    if (el[key as keyof ICreateProductVariantParams] === value) {
                        delete el[key as keyof ICreateProductVariantParams];
                    }
                }
            });
        }

        if (validateForm()) {
            const tempProduct: ICreateProductParams = {
                title: productTitle!,
                description: productDescription!,
                weightUnit: productWeightUnit!,
                dimUnit: productDimUnit!,
                weight: productWeight!,
                height: productHeight!,
                width: productWidth!,
                length: productLength!,
                retailPrice: Math.trunc(productRetailPrice! * 100) as float,
                declaredValue: Math.trunc(productDeclaredValue! * 100) as float,
                listed: productListed!,
                moqValue: productMOQValue!,
                permit24V: productPermit24V!,
                permitPurple: productPermitPurple!,
                guid: productGuid
            };

            const categoryIds = productCategories.map((el) => {
                return el.id;
            });

            const shippingGroupId = productShippingGroup?.id ? productShippingGroup.id : null;

			await ArchiveProduct(productId);
            const product = await CreateProductService(tempProduct, tempVariants, categoryIds, shippingGroupId, productImage);
            if (!product) {
                toast.error("Something went wrong");
                return;
            }

            toast("Product Created: Redirecting...");
            setTimeout(() => {
                navigate("/products/", {replace: true});
            }, 1500);
        }
    };
    /*End Third Slide */

    const handleSubmit = async () => {
        if (validateForm()) {
            if (
                !window.confirm(
                    "If there are variants associated with this product, make sure you go through the entire 3 step process to update the product. Create Variants -> Next -> Submit"
                )
            ) {
                return;
            }

            const tempProduct: ICreateProductParams = {
                title: productTitle!,
                description: productDescription!,
                weightUnit: productWeightUnit!,
                dimUnit: productDimUnit!,
                weight: productWeight!,
                height: productHeight!,
                width: productWidth!,
                length: productLength!,
                retailPrice: Math.trunc(productRetailPrice! * 100) as float,
                declaredValue: Math.trunc(productDeclaredValue! * 100) as float,
                listed: productListed!,
                moqValue: productMOQValue!,
                permit24V: productPermit24V!,
                permitPurple: productPermitPurple!,
                guid: productGuid
            };

            const categoryIds = productCategories.map((el) => {
                return el.id;
            });

            const shippingGroupId = productShippingGroup?.id ? productShippingGroup.id : null;

			await ArchiveProduct(productId);
            const product = await CreateProductService(tempProduct, [], categoryIds, shippingGroupId, productImage);
            if (!product) {
                toast.error("Error creating product");
                return;
            }

            toast("Product Created: Redirecting...");
            setTimeout(() => {
                navigate("/products/", {replace: true});
            }, 1500);
        } else {
            toast.error("Complete all required form fields");
        }
    };

	const deleteProduct = async () => {
		if (!window.confirm("Are you sure you want to delete this product?")) return;

		const response = await ArchiveProduct(productId);
		if (!response) {
			toast.error("Error deleting product");
			return;
		}

		navigate("/products");
	}

    const loadImages = async (productImage: IProductImage | null) => {
        if (!productImage) return;
        
        const res = await RetrieveProductImageBlob(productImage.id);
        if(res)
            setImageSrc({src: URL.createObjectURL(res)});
    };

    const loadNewImage = async (productImage: IProductImage | null) => {
        setProductImage(productImage);
        loadImages(productImage);
    }
    
    useEffect(() => {
        if (dropDownSelected) {
            const tempProductCategories: IProductCategory[] = dropDownSelected.map((el) => {
                return { id: el.value, name: el.label, updatedAt: new Date(), createdAt: new Date(), deletedAt: new Date() };
            });
            setProductCategories(tempProductCategories);
        }
    }, [dropDownSelected]);

    return dropDownSelected === undefined ||
        productDeclaredValue === undefined ||
        productRetailPrice === undefined ||
        productListed === undefined ||
        productMOQValue === undefined ||
        productTitle === undefined ||
        productDescription === undefined ||
        productLength === undefined ||
        productWidth === undefined ||
        productDimUnit === undefined ||
        productHeight === undefined ||
        productWeight === undefined ||
        productWeightUnit === undefined ||
        productPermit24V === undefined ? (
        <Loading center />
    ) : (
        <Page className="create_product_page">
            <TopBar title="Edit Product">
				<Button color="red" style="outline" rounded onClick={deleteProduct}>
					Delete Product
				</Button>
			</TopBar>

            <div className="flex flex-col h-full max-h-full">
                <div className={`form_slider slide-${activeSlide}`}>
                    <form>
                        {/* SLIDE 1 */}
                        <div className={`form_slide`}>
                            <div className="form_slide_content">
                                <div className="form_group">
                                    <div className="max-w-xs">
                                        <label>Listed</label>
                                        <input type="checkbox" required onChange={(e) => setProductListed(e.target.checked)} defaultChecked={productListed} />
                                    </div>
                                    <div className="max-w-xs">
                                        <label>Require 24V Approval</label>
                                        <input type="checkbox" required onChange={(e) => setProductPermit24V(e.target.checked)} defaultChecked={productPermit24V} />
                                    </div>
                                    <div className="max-w-xs">
                                        <label>Require Purple Approval</label>
                                        <input type="checkbox" required onChange={(e) => setProductPermitPurple(e.target.checked)} defaultChecked={productPermitPurple} />
                                    </div>
                                    <div className="max-w-xs">
                                        <label>Image</label>
                                        <ProductImageUpload onImageUploadHandler={CreateProductImage} productImage={productImage} imageSrc={imageSrc} setProductImage={loadNewImage} />
                                    </div>                                    
                                </div>
                                <div className="form_group">
                                    <div>
                                        <label>Product Title</label>
                                        <input
                                            onChange={(e) => handleProductTitleChange(e.target.value)}
                                            value={productTitle}
                                            placeholder="required"
                                            className={formError.error && formError.error.productTitle ? "form_outline_error" : ""}
                                        />
                                        {formError.error && formError.error.productTitle ? (
                                            <div className="form_input_error">{formError.error.productTitle}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form_group">
                                    <div>
                                        <label>Description</label>
                                        <textarea
                                            onChange={(e) => setProductDescription(e.target.value)}
                                            value={productDescription}
                                            placeholder="not required"
                                        />
                                    </div>
                                </div>
                                <div className="form_group">
                                    <div className="max-w-xs">
                                        <label>MOQ Value</label>
                                        <input
                                            onChange={(e) => handleProductMOQValueChange(float.tryParse(e.target.value))}
                                            min={0}
                                            type="number"
                                            placeholder="MOQ value per purchase of product"
                                            defaultValue={productMOQValue}
                                            className={formError.error && formError.error.productMOQValue ? "form_outline_error" : ""}
                                        />
                                        {formError.error && formError.error.productMOQValue ? (
                                            <div className="form_input_error">{formError.error.productMOQValue}</div>
                                        ) : null}
                                    </div>
                                    {shippingGroups ? (
                                        <div>
                                            <label>Shipping Group</label>
                                            <select
                                                onChange={(e) => handleProductShippingGroupChange(int.parse(e.target.value))}
                                                required={true}
                                                name="shippingGroup"
                                                placeholder="required"
                                                defaultValue={productShippingGroup ? productShippingGroup.id : undefined}
                                            >
                                                <option value={undefined}>None</option>;
                                                {shippingGroups?.map((el) => {
                                                    return <option value={el.id}>{el.name}</option>;
                                                })}
                                            </select>
                                        </div>
                                    ) : (
                                        <Loading />
                                    )}
                                    {dropDownCategories && dropDownSelected ? (
                                        <div>
                                            <label>Categories</label>
                                            <MultiSelect
                                                options={dropDownCategories}
                                                value={dropDownSelected}
                                                onChange={setDropdownSelected}
                                                hasSelectAll={false}
                                                labelledBy={"Select"}
                                                disableSearch={true}
                                            />
                                        </div>
                                    ) : (
                                        <Loading />
                                    )}
                                </div>
                                <div className="form_group">
                                    <div>
                                        <label>Weight Unit</label>
                                        <select
                                            onChange={(e) => handleProductWeightUnitChange(e.target.value as WeightUnit)}
                                            required={true}
                                            name="weightUnit"
                                            value={productWeightUnit}
                                            placeholder="Weight Unit"
                                        >
                                            <option value="oz">oz</option>
                                            <option value="kg">kg</option>
                                            <option value="lb">lb</option>
                                            <option value="g">g</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Weight</label>
                                        <input
                                            onChange={(e) => handleProductWeightChange(float.tryParse(e.target.value))}
                                            defaultValue={productWeight}
                                            type="number"
                                            min={0}
                                            placeholder="required"
                                            className={formError.error && formError.error.productWeight ? "form_outline_error" : ""}
                                        />
                                        {formError.error && formError.error.productWeight ? (
                                            <div className="form_input_error">{formError.error.productWeight}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form_group">
                                    <div>
                                        <label>Dimensions Unit</label>
                                        <select
                                            onChange={(e) => handleProductDimUnitChange(e.target.value as DimensionalUnit)}
                                            name="dimUnit"
                                            required={true}
                                            value={productDimUnit}
                                            placeholder="Dimension Unit"
                                        >
                                            <option value="in">in</option>
                                            <option value="cm">cm</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Length</label>
                                        <input
                                            onChange={(e) => handleProductLengthChange(float.tryParse(e.target.value))}
                                            required={true}
                                            min={0}
                                            type="number"
                                            name="length"
                                            defaultValue={productLength}
                                            placeholder="required"
                                            className={formError.error && formError.error.productLength ? "form_outline_error" : ""}
                                        />
                                        {formError.error && formError.error.productLength ? (
                                            <div className="form_input_error">{formError.error.productLength}</div>
                                        ) : null}
                                    </div>
                                    <div>
                                        <label>Height</label>
                                        <input
                                            onChange={(e) => handleProductHeightChange(float.tryParse(e.target.value))}
                                            required={true}
                                            min={0}
                                            type="number"
                                            name="height"
                                            defaultValue={productHeight}
                                            placeholder="required"
                                            className={formError.error && formError.error.productHeight ? "form_outline_error" : ""}
                                        />
                                        {formError.error && formError.error.productHeight ? (
                                            <div className="form_input_error">{formError.error.productHeight}</div>
                                        ) : null}
                                    </div>
                                    <div>
                                        <label>Width</label>
                                        <input
                                            onChange={(e) => handleProductWidthChange(float.tryParse(e.target.value))}
                                            required={true}
                                            min={0}
                                            type="number"
                                            name="width"
                                            defaultValue={productWidth}
                                            placeholder="required"
                                            className={formError.error && formError.error.productWidth ? "form_outline_error" : ""}
                                        />
                                        {formError.error && formError.error.productWidth ? (
                                            <div className="form_input_error">{formError.error.productWidth}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form_group">
                                    <div>
                                        <label>Retail Price</label>
                                        <input
                                            onChange={(e) => handleProductRetailPriceChange(float.tryParse(e.target.value))}
                                            required={true}
                                            min={0}
                                            type="number"
                                            name="retailPrice"
                                            defaultValue={productRetailPrice}
                                            placeholder="USD"
                                            className={formError.error && formError.error.productRetailPrice ? "form_outline_error" : ""}
                                        />
                                        {formError.error && formError.error.productRetailPrice ? (
                                            <div className="form_input_error">{formError.error.productRetailPrice}</div>
                                        ) : null}
                                    </div>
                                    <div>
                                        <label>Declared Value</label>
                                        <input
                                            onChange={(e) => handleProductDeclaredValue(float.tryParse(e.target.value))}
                                            required={true}
                                            min={0}
                                            type="number"
                                            name="declaredValue"
                                            defaultValue={productDeclaredValue}
                                            placeholder="USD"
                                            className={formError.error && formError.error.productDeclaredValue ? "form_outline_error" : ""}
                                        />
                                        {formError.error && formError.error.productDeclaredValue ? (
                                            <div className="form_input_error">{formError.error.productDeclaredValue}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            {/* Variation Button: only works if default values are not empty */}
                            <div
                                className={`button outline slide_next left ${formError.error ? "disabled" : ""}`}
                                /*                                 onClick={() => setActiveSlide(activeSlide + 1)} */
                                onClick={handleCreateVariantsButton}
                            >
                                Create Variants
                            </div>
                            <div
                                className={`button primary slide_next right ${formError.error ? "disabled" : ""}`}
                                /*                                 onClick={() => setActiveSlide(activeSlide + 1)} */
                                onClick={handleSubmit}
                            >
                                Submit
                            </div>
                        </div>

                        {/* SLIDE 2 */}
                        <div className={`form_slide`}>
                            <div className="form_slide_content">
                                {Array.from({ length: keyValueRows }, (el, i) => (
                                    <div key={i} className="form_group">
                                        <div>
                                            <label>{`Attribute ${i + 1}`}</label>
                                            <input
                                                type="text"
                                                placeholder="i.e. Color"
                                                value={keyValuePairs[i] && keyValuePairs[i].name ? keyValuePairs[i].name : ""}
                                                onChange={(e) => handleAttributeChange(e.target.value, i)}
                                            ></input>
                                            <div className="delete" onClick={() => handleDeleteKeyValue(i)}>
                                                Delete
                                            </div>
                                        </div>
                                        <div>
                                            <label>{`Separate Values by Comma`}</label>
                                            <input
                                                type="text"
                                                placeholder="i.e. Red, Blue, Green, etc."
                                                value={keyValuePairs[i] && keyValuePairs[i].value ? keyValuePairs[i].value : ""}
                                                onChange={(e) => handleValueChange(e.target.value, i)}
                                            ></input>
                                        </div>
                                    </div>
                                ))}

                                <div className="variant button" onClick={() => addAttributeRow()}>
                                    Add Attribute <FontAwesomeIcon icon={faPlus} />
                                </div>
                            </div>

                            <div className="button slide_previous back" onClick={() => setActiveSlide(activeSlide - 1)}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                                Go Back
                            </div>
                            <div className="button slide_next outline next" onClick={() => generateCombinations()}>
                                Next
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        </div>

                        {/* SLIDE 3 */}
                        <div className={`form_slide`}>
                            {renderSlide3 ? (
                                <div className="form_slide_content">
                                    <h3 className="variation_warning">*Only modify default values if variant requires it</h3>
                                    {combinations.map((combo, i) => {
                                        return (
                                            <div key={combo.map((el) => el.name + el.value).join()} className="variant_product_row">
                                                <div onClick={() => deleteVariant(i)} className="delete">
                                                    Delete
                                                </div>
                                                <VariantProduct combo={combo} index={i} mainProduct={mainProduct} updateVariant={handleVariantChanges} />
                                            </div>
                                        );
                                    })}
                                    <div
                                        className="button back slide_previous"
                                        onClick={() => {
                                            setRenderSlide3(false);
                                            setActiveSlide(activeSlide - 1);
                                        }}
                                    >
                                        Go Back
                                    </div>
                                    <div className="button slide_next next primary" onClick={() => handleVariationSubmit()}>
                                        Submit
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </form>
                </div>
            </div>
        </Page>
    );
}
