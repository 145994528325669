import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { RetrieveCompanies, RetrieveCompany, UpdateCompany as UpdateCompanyService } from "@frontend/services/companies";
import { CreateDiscountPackage, DeleteDealerPackage, UpdateDealerPackage } from "@frontend/services/dealer-packages";
import {
    BuildingType,
    CompanyType,
    float,
    ICompany,
    ICreateDiscountPackageParams,
    IUpdateDiscountPackageParams,
    IDiscountPackage,
    int,
    IUpdateCompanyParams,
    ProductLine,
    IUser,
    ProductCertification,
} from "@common.abstractions";
import { addressEquals, cleanPhoneNumber, formatDate, moneyStringToCents } from "@common.tools";
import { Loading } from "@frontend/components/misc/loading";
import { Page } from "@frontend/components/misc/layout";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import Button from "@frontend/components/misc/button/Button";
import { toast } from "react-toastify";
import { Modal } from "@frontend/components/misc/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { RetrieveUser, RetrieveUsers } from "@frontend/services/users";
import { Equals, Exists, Not } from '@common.api'
import { useNavigate } from "react-router-dom-v5-compat";

export default function UpdateCompany() {
    const navigate = useNavigate();
    const params = useParams<{ companyId: string }>();
    const [companyId] = useState<int>(int.parse(params.companyId));
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        getValues,
        formState: {
            errors,
        }
    } = useForm();

    const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm();
    const { register: register3, handleSubmit: handleSubmit3, formState: { errors: errors3 } } = useForm();

    const [company, setCompany] = useState<ICompany>();
    const [loading, setLoading] = useState(false);
    const watchCompanyType = watch("companyType");
    const [users, setUsers] = useState<IUser[]>();
    const [assignedOwner, setAssignedOwner] = useState<IUser>();
    const [dealers, setDealers] = useState<ICompany[]>();
    const [parentCompanyId, setParentCompanyId] = useState<int>();
    const [parentCompany, setParentCompany] = useState<ICompany>();
    const [isParentCompany, setIsParentCompany] = useState<boolean>(false);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [editPackageModal, setEditPackageModal] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const [packageId, setPackageId] = useState<int>();
    const [moq, setMoq] = useState<float>();
    const [discountPercent, setDiscountPercent] = useState<float>();
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    useEffect(() => {
        if (watchCompanyType === CompanyType.DEALER) {
            setValue("timeZone", undefined);
        } else {
            if (company?.timeZone) {
                setValue("timeZone", company.timeZone);
            }
        }
    }, [watchCompanyType]);

    const closePackageModal = () => {
        setOpenModal(false);
    }

    const openEditPackageModal = async (discountPackage: IDiscountPackage) => {
        const startDate = new Date(discountPackage.startDate);
        const endDate = new Date(discountPackage.endDate);
        const differenceInDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
        const annualizedMOQ = Math.round((365 / differenceInDays) * discountPackage.moq);

        setPackageId(discountPackage.id)
        setName(discountPackage.name);
        setMoq(float(annualizedMOQ));
        setDiscountPercent(discountPackage.discountPercent);
        setStartDate(discountPackage.startDate);
        setEndDate(discountPackage.endDate);
        setEditPackageModal(true);
    }

    const closeEditPackageModal = () => {
        setEditPackageModal(false);
    }

    const updatePackage = async (values: any) => {
        const packageParams: IUpdateDiscountPackageParams = {
            name: values.packageName,
            annualMoq: values.packageMOQ,
            discountPercent: values.packageDiscountPercent,
            startDate: new Date(Date.parse(values.packageLastPaidDate)),
            endDate: new Date(Date.parse(values.packageExpirationDate))
        };

        const editDiscountPackageResponse = await UpdateDealerPackage(values.packageId, packageParams);

        if (!editDiscountPackageResponse) {
            toast.error('Something went wrong');
            return;
        }
        toast.success('Package Saved');
        setEditPackageModal(false);
        loadCompany();
    }

    const deletePackage = async (discountPackage: IDiscountPackage) => {
        const deletePackageResponse = await DeleteDealerPackage(discountPackage.id);
        if (!deletePackageResponse) {
            toast.error('Something went wrong');
            return;
        }
        toast.success('Package Deleted')
        loadCompany();
    };

    const createPackage = async (values: any) => {
        const packageParams: ICreateDiscountPackageParams = {
            discountPercent: values.packageDiscountPercent,
            endDate: new Date(Date.parse(values.packageExpirationDate)),
            startDate: new Date(Date.parse(values.packageLastPaidDate)),
            annualMoq: values.packageMOQ,
            name: values.packageName,
        };

        const discountPackageResponse = await CreateDiscountPackage(companyId, packageParams);

        if (!discountPackageResponse) {
            toast.error("Unable to Add Package");
            return;
        }

        toast.success('Package Added');
        setOpenModal(false);
        loadCompany();
    }

    const loadUsers = async () => {
        const users = await RetrieveUsers({
            filters: {
                deletedAt: Not(Exists()),
                company: {
                    companyType: Equals(CompanyType.BRANCH),
                    id: Equals(int(40))
                }
            },
            relations: ['company']
        })
        setUsers(users);
    }

    const findUser = async (userId: int) => {
        const user = await RetrieveUser(userId);
        if (!user) {
            console.log('Could not retrieve user');
            return;
        }
        setAssignedOwner(user);
    }

    const loadCompany = async () => {
        setLoading(true);
        const company = await RetrieveCompany(companyId);
        if (!company) {
            toast.error("Could not load company");
            setLoading(false);
            return;
        }
        if (!company?.packages) {
            toast.error("Could not load packages");
            setLoading(false);
            return;
        }

        company.packages.sort((a, b) => {
            return (a.startDate > b.startDate) ? 1 : ((a.startDate < b.startDate) ? -1 : 0);
        })

        setCompany(company);

        if (company.parentCompanyId) {
            const parentCompany = await RetrieveCompany(company.parentCompanyId);
            if (!parentCompany) {
                toast.error("Could not load parent company");
                return;
            }
            setParentCompany(parentCompany);
        }
        if (company.isParentCompany) setIsParentCompany(true);

        let tempLatestPackage = company.packages.find((el) => new Date(el.startDate) < new Date() && new Date(el.endDate) > new Date());

        if (!tempLatestPackage) {
            tempLatestPackage = company.packages.find((el) => new Date(el.startDate) > new Date());
            if (!tempLatestPackage) {
                toast.error("Warning: Company does not have an active discount package");
                setLoading(false);
                return;
            }
        }
        setLoading(false);
    };

    const loadParentCompanies = async () => {
        const dealers = await RetrieveCompanies({
            filters: {
                companyType: Equals(CompanyType.DEALER),
                isParentCompany: Equals(true)
            }
        });
        if (!dealers) return;
        setDealers(dealers);
    };

    useEffect(() => {
        loadCompany();
    }, [companyId]);

    useEffect(() => {
        loadUsers();
        loadParentCompanies();
    }, [])

    const submitCompanyForm = async (values: any) => {
        if (!company) throw new Error("Company null");

        if (!values.timeZone && values.companyType === CompanyType.BRANCH) {
            toast.error("Branches need a timezone selected");
            return;
        }

        if (values.timeZone && values.timeZone !== "") {
            if (values.timeZone && values.timeZone !== "") {
                const timeZoneNameAndAbbr = values.timeZone.split(", ");
                values.timeZone = { zoneName: timeZoneNameAndAbbr[0], abbreviation: timeZoneNameAndAbbr[1] }
            }
        } else {
            values.timeZone = null;
        }

        values.phone = cleanPhoneNumber(values.phone);
        values.personalPhone = cleanPhoneNumber(values.personalPhone);
        values.primaryServiceCharge = (values.primaryServiceCharge as number) * 100;
        values.secondaryServiceCharge = (values.secondaryServiceCharge as number) * 100;

        if (!Array.isArray(values.buildingTypes)) {
            let arr = new Array();
            arr.push(values.buildingTypes);
            values.buildingTypes = arr;
        }

        if (!Array.isArray(values.productLines)) {
            let arr = new Array();
            arr.push(values.productLines);
            values.productLines = arr;
        }

        if(!Array.isArray(values.productCertifications)) {
            let arr = new Array();
            arr.push(values.productCertifications);
            values.productCertifications = arr;
        }

        const patch = {} as IUpdateCompanyParams;

        /* If the address has changed, update it. */
        if (values.address && !addressEquals(values.address, company?.address)) {
            patch.address = values.address;
        }
        if (values.companyType !== company.companyType) {
            patch.companyType = values.companyType;
        }
        if (values.name !== company.name) {
            patch.name = values.name;
        }
        if (values.phone !== company.phone) {
            patch.phone = values.phone;
        }

        if (values.personalPhone === undefined) {
            patch.personalPhone = null;
        } else {
            patch.personalPhone = values.personalPhone;
        }
        if (values.email !== company.email) {
            patch.email = values.email;
        }

        if (values.timeZone !== company.timeZone) {
            patch.timeZone = values.timeZone;
        }
        if (values.buildingTypes !== company.buildingTypes) {
            patch.buildingTypes = values.buildingTypes;
        }
        if (values.facebookUrl !== company.facebookUrl) {
            patch.facebookUrl = values.facebookUrl;
        }
        if (values.instagramUrl !== company.instagramUrl) {
            patch.instagramUrl = values.instagramUrl;
        }
        if (values.websiteUrl !== company.websiteUrl) {
            patch.websiteUrl = values.websiteUrl;
        }
        if (values.productLines !== company.productLines) {
            patch.productLines = values.productLines;
        }
        if (values.productCertifications != company.productCertifications) {
            console.log("certifications changed");
            patch.productCertifications = values.productCertifications;
        }
        if (company.owner !== patch.owner) {
            patch.owner = assignedOwner;
        }
        if (company.parentCompanyId !== parentCompanyId) {
            patch.parentCompanyId = parentCompanyId;
        }
        if (company.isParentCompany !== isParentCompany) {
            patch.isParentCompany = isParentCompany;
        }

        let formattedMarketingSpend;
        if (values.monthlyMarketingSpend) {
            formattedMarketingSpend = moneyStringToCents(values.monthlyMarketingSpend);
        }

        patch.monthlyMarketingSpend = formattedMarketingSpend ? formattedMarketingSpend : null;
        patch.marketingZipCodes = values.marketingZipCodes ? values.marketingZipCodes.split(',').map((el: any) => el.trim()) : [];

        const response = await UpdateCompanyService(companyId, patch);

        if (!response) {
            toast.error("Unable to update company");
            return;
        }

        navigate(-1);
    };

    if (loading) return <Loading center />;

    if (!company) return <div className="inset-center text-gray-500">Company details failed to load</div>;

    return (
        <Page>
            <TopBar title={company.name}></TopBar>

            <form onSubmit={handleSubmit(submitCompanyForm)} className="pb-40">
                {/* Company Info */}
                <div className="form_slide">
                    <h2 className="text-gray-500 text-2xl mb-6">Company Information</h2>

                    <div className="form_group">
                        <div>
                            <label>Company Name</label>
                            <input
                                className={`form-control ${errors.name && "form_outline_error"}`}
                                type="text"
                                placeholder="Company Name"
                                defaultValue={company.name}
                                {...register('name', { required: "Company name is required" })} />
                            {errors.name && <div className="form_input_error">{(errors.name as any).message}</div>}
                        </div>
                        <div>
                            <label>Company Type</label>
                            <select
                                {...register('companyType', { required: "Required" })}
                                defaultValue={company.companyType}>
                                <option value={CompanyType.DEALER}>Dealer</option>
                                <option value={CompanyType.BRANCH}>Branch</option>
                            </select>
                            {errors.companyType && <div className="form_input_error">{(errors.companyType as any).message}</div>}
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Phone Number</label>
                            <input
                                className={`form-control ${errors.phone && "form_outline_error"}`}
                                type="tel"
                                placeholder="Company Phone Number"
                                {...register('phone', {
                                    required: "Phone number is required",
                                    validate: (val) => cleanPhoneNumber(val) !== undefined,
                                })}
                                defaultValue={company.phone}
                            />
                            {errors.phone && <div className="form_input_error">{(errors.phone.message) as any}</div>}
                            {errors.phone && errors.phone.type === "validate" && <div className="form_input_error">Phone number is invalid</div>}
                        </div>
                        <div>
                            <label>Personal Number</label>
                            <input
                                className={`form-control ${errors.personalPhone && "form_outline_error"}`}
                                type="tel"
                                placeholder="optional"
                                {...register('personalPhone')}
                                defaultValue={company.personalPhone ? company.personalPhone : undefined}
                            />
                            {errors.personalPhone && <div className="form_input_error">{(errors.personalPhone.message) as any}</div>}
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Email</label>
                            <input
                                className={`form-control ${errors.email && "form_outline_error"}`}
                                type="text"
                                placeholder="Company Email Address"
                                {...register('email', {
                                    required: "Email is required",
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'Invalid email address'
                                    }
                                })}
                                defaultValue={company.email}
                            />
                            {errors.email && <div className="form_input_error">{(errors.email.message) as any}</div>}
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Street Address</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).streetAddress! && "form_outline_error"}`}
                                type="text"
                                placeholder="Street Address"
                                defaultValue={company.address.streetAddress}
                                {...register('address.streetAddress', { required: "Street Address is required" })} />
                            {errors.address && (errors.address as any).streetAddress && <div className="form_input_error">{(errors.address as any).streetAddress.message}</div>}
                        </div>
                        <div>
                            <label>City</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).city && "form_outline_error"}`}
                                type="text"
                                placeholder="City"
                                defaultValue={company.address.city}
                                {...register('address.city', { required: "City is required" })} />
                            {errors.address && (errors.address as any).city && <div className="form_input_error">{(errors.address as any).city.message}</div>}
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>State/Region</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).state && "form_outline_error"}`}
                                type="text"
                                placeholder="State/Region"
                                defaultValue={company.address.state}
                                {...register('address.state', {
                                    required: "State is required",
                                    pattern: {
                                        value: /^[A-Za-z]{2}$/,
                                        message: 'Invalid state code',
                                    }
                                })}
                            />
                            {errors.address && (errors.address as any).state && <div className="form_input_error">{(errors.address as any).state.message}</div>}
                        </div>
                        <div>
                            <label>Postal Code</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).zip && "form_outline_error"}`}
                                type="text"
                                placeholder="Postal Code"
                                defaultValue={company.address.zip}
                                {...register('address.zip', { required: "Postal Code is required" })} />
                            {errors.address && (errors.address as any).zip && <div className="form_input_error">{(errors.address as any).zip.message}</div>}
                        </div>
                        <div>
                            <label>Country/Region</label>
                            <input
                                className={`form-control ${errors.address && (errors.address as any).country && "form_outline_error"}`}
                                type="text"
                                placeholder="Country/Region"
                                defaultValue={company.address.country}
                                {...register('address.country', { required: "Country/Region is required" })} />
                            {errors.address && (errors.address as any).country && <div className="form_input_error">{(errors.address as any).country.message}</div>}
                        </div>
                    </div>

                    {(company.companyType === CompanyType.BRANCH || watchCompanyType === CompanyType.BRANCH) && (
                        <div className="form_group">
                            <div>
                                <label>IANA TimeZone</label>
                                <select
                                    className={`form-control ${errors.timeZone && "form_outline_error"}`}
                                    {...register('timeZone')}
                                    defaultValue={company.timeZone ? `${company.timeZone.zoneName}, ${company.timeZone.abbreviation}` : undefined}>
                                    <option value="">Select</option>
                                    <option value="US/Pacific, PT">Pacific Time (UTC - 08:00)</option>
                                    <option value="US/Mountain, MT">Mountain Time (UTC - 07:00)</option>
                                    <option value="US/Arizona, MST">Arizona (UTC - 07:00)</option>
                                    <option value="US/Central, CT">Central Time (UTC - 06:00)</option>
                                    <option value="US/Eastern, ET">Eastern Time (UTC - 05:00)</option>
                                </select>
                            </div>
                        </div>
                    )}

                    {(company.companyType === CompanyType.DEALER || watchCompanyType === CompanyType.DEALER) && (
                        <div>
                            <div className="form_group">
                                <div>
                                    <label>Assigned Dealer Manager</label>
                                    <Select
                                        options={users?.map((user) => ({
                                            value: user.accountId,
                                            label: `${user.firstname} ${user.lastname}`
                                        }))}
                                        onChange={(val) => findUser(int.parse(val?.value))}
                                        defaultValue={
                                            {
                                                value: company.owner ? company.owner.accountId : null,
                                                label: company.owner ? `${company.owner?.firstname} ${company.owner?.lastname}` : null
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form_checkbox_container">
                                <div className="form_checkbox">
                                    <label className="form-check-label">Parent Company</label>
                                    <input className="form-check-input" type="checkbox" checked={isParentCompany} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsParentCompany(e.target.checked)}></input>
                                </div>
                            </div>

                            {!isParentCompany && (
                                <div className="form_group">
                                    <div>
                                        <label>Assigned Parent Company</label>
                                        <Select
                                            options={dealers?.map((dealer) => ({
                                                value: dealer.id,
                                                label: dealer.name
                                            }))}
                                            onChange={(val) => setParentCompanyId(int.parse(val?.value))}
                                            defaultValue={
                                                {
                                                    value: company.parentCompanyId ? company.parentCompanyId : null,
                                                    label: parentCompany ? parentCompany.name : null
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="form_group">
                        <div>
                            <label>Website Link</label>
                            <input
                                className={`form-control ${errors.websiteUrl && "form_outline_error"}`}
                                type="url"
                                placeholder="Website Url"
                                defaultValue={company.websiteUrl ? company.websiteUrl : undefined}
                                {...register('websiteUrl')} />
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Facebook Link</label>
                            <input
                                className={`form-control ${errors.facebookUrl && "form_outline_error"}`}
                                type="url"
                                placeholder="Facebook Url"
                                defaultValue={company.facebookUrl ? company.facebookUrl : undefined}
                                {...register('facebookUrl')} />
                        </div>
                    </div>

                    <div className="form_group">
                        <div>
                            <label>Instagram Link</label>
                            <input
                                className={`form-control ${errors.instagramUrl && "form_outline_error"}`}
                                type="url"
                                placeholder="Instagram Url"
                                defaultValue={company.instagramUrl ? company.instagramUrl : undefined}
                                {...register('instagramUrl')} />
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="monthlyMarketingSpend" className="block text-sm font-medium text-gray-500">
                        Monthly Marketing Spend:
                    </label>
                    <input
                        min={0}
                        type="number"
                        step={"any"}
                        id="monthlyMarketingSpend"
                        defaultValue={company.monthlyMarketingSpend ? (company.monthlyMarketingSpend / 100).toFixed(2) : ''}
                        className="form-input mt-1 block w-full rounded-md border-grey-100 border-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                        {...register('monthlyMarketingSpend')}
                        placeholder="optional"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="marketingZipCodes" className="block text-sm font-medium text-gray-700">
                        Marketing Zip Codes:
                    </label>
                    <input
                        type="text"
                        id="marketingZipCodes"
                        className="form-input mt-1 block w-full rounded-md border-grey-100 border-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                        defaultValue={company.marketingZipCodes ? company.marketingZipCodes.join(', ') : ''}
                        {...register('marketingZipCodes')}
                        placeholder="comma separated"
                    />
                </div>

                {/* Service Info */}
                <div className="form_slide">
                    <h2 className="text-gray-500 text-2xl mt-10 mb-6">Service Information</h2>

                    <div className="form_group">
                        <div className={`form_checkbox_wrapper ${errors.buildingTypes && "form_outline_error"}`}>
                            <label>
                                Targets
                                {errors.buildingTypes && <div className="form_input_error">Required</div>}
                            </label>
                            <div className="form_checkbox_container">
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="targetsResidential">
                                        Residential
                                    </label>
                                    <input
                                        id="targetsResidential"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Targets Residential Customers"
                                        {...register('buildingTypes', {
                                            validate: () => getValues("buildingTypes").length !== 0,
                                        })}
                                        defaultChecked={company.buildingTypes.includes(BuildingType.RESIDENTIAL)}
                                        value={BuildingType.RESIDENTIAL} />
                                </div>
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="targetsCommercial">
                                        Commercial
                                    </label>
                                    <input
                                        id="targetsCommercial"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Targets Commercial Customers"
                                        {...register('buildingTypes', {
                                            validate: () => getValues("buildingTypes").length !== 0,
                                        })}
                                        defaultChecked={company.buildingTypes.includes(BuildingType.COMMERCIAL)}
                                        value={BuildingType.COMMERCIAL} />
                                </div>
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="targetsMunicipal">
                                        Municipal
                                    </label>
                                    <input
                                        id="targetsMunicipal"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Targets Municipal Customers"
                                        {...register('buildingTypes', {
                                            validate: () => getValues("buildingTypes").length !== 0,
                                        })}
                                        defaultChecked={company.buildingTypes.includes(BuildingType.MUNICIPAL)}
                                        value={BuildingType.MUNICIPAL} />
                                </div>
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="targetsIndustrial">
                                        Industrial
                                    </label>
                                    <input
                                        id="targetsIndustrial"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Targets Industrial Customers"
                                        {...register('buildingTypes', {
                                            validate: () => getValues("buildingTypes").length !== 0,
                                        })}
                                        defaultChecked={company.buildingTypes.includes(BuildingType.INDUSTRIAL)}
                                        value={BuildingType.INDUSTRIAL} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form_group">
                        <div className={`form_checkbox_wrapper ${errors.productLines && "form_outline_error"}`}>
                            <label>
                                Product Lines
                                {errors.productLines && <div className="form_input_error">Required</div>}
                            </label>

                            <div className="form_checkbox_container">
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="dealsClassic">
                                        Classics
                                    </label>

                                    <input
                                        id="dealsClassic"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Deals Classic"
                                        {...register('productLines', {
                                            validate: () => getValues("productLines").length !== 0,
                                        })}
                                        defaultChecked={company.productLines.includes(ProductLine.CLASSIC)}
                                        value={ProductLine.CLASSIC} />
                                </div>
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="dealsColorChanging">
                                        Color Changing
                                    </label>
                                    <input
                                        id="dealsColorChanging"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Deals Color Changing"
                                        {...register('productLines', {
                                            validate: () => getValues("productLines").length !== 0,
                                        })}
                                        defaultChecked={company.productLines.includes(ProductLine.COLOR_CHANGING)}
                                        value={ProductLine.COLOR_CHANGING} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form_group">
                        <div className={`form_checkbox_wrapper ${errors.productCertifications && "form_outline_error"}`}>
                            <label>
                                Product Certifications
                                {errors.productCertifications && <div className="form_input_error">Required</div>}
                            </label>

                            <div className="form_checkbox_container">
                                <div className="form_checkbox">
                                    <label className="form-check-label" htmlFor="certs24V">
                                        24V Permit
                                    </label>

                                    <input
                                        id="certs24V"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="24V Permit"
                                        {...register("productCertifications", //{
                                        //     validate: () => getValues("productCertifications").length !== 0,
                                        // }
                                        )}
                                        defaultChecked={company.productCertifications.includes(ProductCertification.PERMIT24V)}
                                        value={ProductCertification.PERMIT24V}
                                    />
                                </div>
                                <div className="form_checkbox">
                                <label className="form-check-label" htmlFor="certs_purple">
                                        Purple
                                    </label>

                                    <input
                                        id="certs_purple"
                                        className="form-check-input"
                                        type="checkbox"
                                        placeholder="Purple"
                                        {...register("productCertifications", //{
                                        //     validate: () => getValues("productCertifications.permit").length !== 0,
                                        // }
                                        )}
                                        defaultChecked={company.productCertifications.includes(ProductCertification.PERMIT_PURPLE)}
                                        value={ProductCertification.PERMIT_PURPLE}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Button className="w-96 mt-16" style="solid" type="submit" color="blue">Save Changes</Button>
            </form>

            <Button className="w-96 mt-16" color="blue" style="outline" type="button" onClick={() => setOpenModal(true)}>Add Discount Package</Button>

            {company.packages?.map((el) => {
                const disabled = new Date(el.endDate) < new Date()

                return (
                    <div key={el.id} className="flex mt-8 w-9">
                        <div className="flex-col">
                            <label htmlFor="packageName" className="text-gray-500 text-sm mb-1">Package Name</label>
                            <input type="text" name="packageName" defaultValue={el?.name} disabled={disabled} />
                        </div>

                        <div className="flex-col">
                            <label htmlFor="moq" className="text-gray-500 text-sm mb-1"> MOQ</label>
                            <input type="number" name="packageMOQ" defaultValue={el?.moq} disabled={disabled} />
                        </div>

                        <div className="flex-col">
                            <label htmlFor="discountPercent" className="text-gray-500 text-sm mb-1">Discount Percent</label>
                            <input type="number" name="packageDiscountPercent" defaultValue={el?.discountPercent} disabled={disabled} />
                        </div>

                        <div className="flex-col">
                            <label htmlFor="startDate" className="text-gray-500 text-sm mb-1">Package Start Date</label>
                            <input type="date" name="packageStart" defaultValue={el.startDate ? new Date(new Date(el.startDate).setDate(new Date(el.startDate).getDate())).toISOString().split("T")[0] : undefined} disabled={disabled} />
                        </div>

                        <div className="flex-col">
                            <label htmlFor="endDate" className="text-gray-500 text-sm mb-1">Package End Date</label>
                            <input type="date" name="packageEnd" defaultValue={el.endDate ? new Date(new Date(el.endDate).setDate(new Date(el.endDate).getDate())).toISOString().split("T")[0] : undefined} disabled={disabled} />
                        </div>

                        {new Date(el.endDate) > new Date() && (
                            <Button color="yellow" style="soft" type="button" onClick={() => openEditPackageModal(el)} >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}

                        {el.vNumOrders === 0 && (
                            <Button color="red" style="soft" type="button" onClick={() => deletePackage(el)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}

                    </div>
                )
            })}

            {/* CREATE PACKAGE MODAL */}
            <Modal visible={openModal} closeModal={closePackageModal}>
                <form onSubmit={handleSubmit2(createPackage)}>
                    <div className="flex flex-col p-1">
                        <div className="text-center text-gray-500 text-lg mb-4">Package Information</div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Package Name</label>
                            <input
                                className={`border py-2 px-3 text-gray-500 ${errors2.packageName && "form_outline_error"}`}
                                type="text"
                                {...register2('packageName', { required: "Package Name is required" })}
                                placeholder="Required" />
                            {errors2.packageName && <div className="form_input_error">{errors2.packageName.message?.toString()}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Annual MOQ</label>
                            <input
                                className={`border py-2 px-3 text-gray-500 ${errors2.packageMOQ && "form_outline_error"}`}
                                type="number"
                                min="0"
                                step="1"
                                {...register2('packageMOQ', { required: "MOQ is required" })}
                                onChange={() => setValue("packageNonProratedMOQ", undefined)}
                                placeholder="required" />
                            {errors2.packageMOQ && <div className="form_input_error">{errors2.packageMOQ.message?.toString()}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Discount Percent</label>
                            <input
                                className={`border py-2 px-3 text-gray-500 ${errors2.packageDiscountPercent && "form_outline_error"}`}
                                type="number"
                                min="0"
                                step="1"
                                {...register2('packageDiscountPercent', { required: "Discount Percent is required" })}
                                placeholder="e.g. 30" />
                            {errors2.packageDiscountPercent && <div className="form_input_error">{errors2.packageDiscountPercent.message?.toString()}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Package Start Date</label>
                            <input
                                id="packageLastPaidDate"
                                className={`border py-2 px-3 text-gray-500 ${errors2.packageLastPaidDate && "form_outline_error"}`}
                                type="date"
                                {...register2('packageLastPaidDate', { required: "Start Date is required" })} />
                            {errors2.packageLastPaidDate && <div className="form_input_error">{errors2.packageLastPaidDate.message?.toString()}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Package End Date</label>
                            <input
                                id="packageExpirationDate"
                                className={`border py-2 px-3 text-gray-500 ${errors2.packageExpirationDate && "form_outline_error"}`}
                                type="date"
                                {...register2('packageExpirationDate', { required: "End Date is required" })} />
                            {errors2.packageExpirationDate && <div className="form_input_error">{errors2.packageExpirationDate.message?.toString()}</div>}
                        </div>
                        <Button className="w-96 mt-4" style="solid" type="submit" color="blue" >Save Changes</Button>
                    </div>
                </form>
            </Modal>

            {/* EDIT PACKAGE MODAL */}
            <Modal visible={editPackageModal} closeModal={closeEditPackageModal}>
                <form onSubmit={handleSubmit3(updatePackage)}>
                    <div className="flex flex-col p-1">
                        <div className="text-center text-gray-500 text-lg mb-4">Edit Package</div>
                        <div className="flex flex-col">
                            <input
                                type="number"
                                {...register3('packageId', { required: "Required" })}
                                placeholder="Required"
                                value={packageId}
                                hidden={true}
                                readOnly={true} />
                        </div>

                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Package Name</label>
                            <input
                                className={`border py-2 px-3 text-gray-500 ${errors3.packageName && "form_outline_error"}`}
                                type="text"
                                {...register3('packageName', { required: "Package name is required" })}
                                defaultValue={name} />
                            {errors3.packageName && <div className="form_input_error">{errors3.packageName.message?.toString()}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Annual MOQ</label>
                            <input
                                className={`border py-2 px-3 text-gray-500 ${errors3.packageMOQ && "form_outline_error"}`}
                                type="number"
                                min="0"
                                step="1"
                                {...register3('packageMOQ', { required: "MOQ is required" })}
                                onChange={() => setValue("packageNonProratedMOQ", undefined)}
                                defaultValue={moq} />
                            {errors3.packageMOQ && <div className="form_input_error">{errors3.packageMOQ.message?.toString()}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Discount Percent</label>
                            <input
                                className={`border py-2 px-3 text-gray-500 ${errors3.packageDiscountPercent && "form_outline_error"}`}
                                type="number"
                                min="0"
                                step="1"
                                {...register3('packageDiscountPercent', { required: "Discount percent is required" })}
                                placeholder="e.g. 30"
                                defaultValue={discountPercent} />
                            {errors3.packageDiscountPercent && <div className="form_input_error">{errors3.packageDiscountPercent.message?.toString()}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Package Start Date</label>
                            <input
                                id="packageLastPaidDate"
                                className={`border py-2 px-3 text-gray-500 ${errors3.packageLastPaidDate && "form_outline_error"}`}
                                type="date"
                                {...register3('packageLastPaidDate', { required: "Start Date is required" })}
                                defaultValue={startDate ? formatDate(startDate) : undefined} />
                            {errors3.packageLastPaidDate && <div className="form_input_error">{errors3.packageLastPaidDate.message?.toString()}</div>}
                        </div>
                        <div className="flex flex-col">
                            <label className="text-gray-500 text-sm mb-1">Package End Date</label>
                            <input
                                id="packageExpirationDate"
                                className={`border py-2 px-3 text-gray-500 ${errors3.packageExpirationDate && "form_outline_error"}`}
                                type="date"
                                {...register3('packageExpirationDate', { required: "End Date is required" })}
                                defaultValue={endDate ? formatDate(endDate) : undefined} />
                            {errors3.packageExpirationDate && <div className="form_input_error">{errors3.packageExpirationDate.message?.toString()}</div>}
                        </div>
                        <Button className="w-96 mt-4" style="solid" type="submit" color="blue">Save Changes</Button>
                    </div>
                </form>
            </Modal>
        </Page>
    );
}
