export type int = number & { __int__: void };
export function int(val: number) {
    if (!Number.isInteger(val)) throw new Error("Value is not an integer: " + val );
    return val as int;
}
export namespace int {
    export function parse(val: any) {
        const num = parseInt(val) as int;
        if (isNaN(num)) {
            throw new Error("Unable to parse int from: " + val);
        }
        return num;
    }

    export function tryParse(val: any) {
        try {
            return parse(val);
        }
        catch {
            return undefined;
        }
    }

    export function round(val: number | float) {
        return int(Math.round(val));
    }
    
    export function ceil(val: number | float) {
        return int(Math.ceil(val));
    }

    export function floor(val: number | float) {
        return int(Math.floor(val));
    }
}

export type float = number & { __float__: void };
export function float(val: number) {
    return val as float;
}
export namespace float {
    export function parse(val: any) {
        const num = parseFloat(val) as float;
        if (isNaN(num)) {
            throw new Error("Unable to parse float from: " + val);
        }
        return num;
    }
    
    export function tryParse(val: any) {
        try {
            return parse(val);
        }
        catch {
            return undefined;
        }
    }
}

export enum ProductLine {
    CLASSIC = "classic",
    COLOR_CHANGING = "color_changing",
}

export enum ProductCertification {
    PERMIT24V = "permit24V",
    PERMIT_PURPLE = "permit_purple"
}

export enum InstallationType {
    PROFESSIONAL = "professional",
    DIY = "diy",
}

export enum FinancingInterest {
    YES = "yes",
    NO = "no",
    DEPENDS = "depends",
}

export enum BuildingType {
    RESIDENTIAL = "residential",
    COMMERCIAL = "commercial",
    MUNICIPAL = "municipal",
    INDUSTRIAL = "industrial",
    SINGLE_FAMILY = "single family home",
    CONDO_TOWNHOME = "condo / townhome",
}

export type Address = {
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    geoPoint: GeoPoint | null;
};

export type LatLng = [float, float];

export enum GeoPointType {
    APPROXIMATE = "APPROXIMATE",
    GEOMETRIC_CENTER = "GEOMETRIC_CENTER",
    INTERPOLATED = "RANGE_INTERPOLATED",
    ROOFTOP = "ROOFTOP",
    UNKNOWN = "UNKNOWN",
}

export type GeoPoint = {
    type: GeoPointType;
    latlng: LatLng;
    formattedAddress?: string;
};

export enum DistanceUnit {
    METERS = "m",
    MILES = "mi",
}
